import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import SelectNote from "../components/form/SelectNote";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Image from "../components/elements/Image";
import ButtonFile from "../components/form/ButtonFile";
import { allowedImageExtensions, NextStepMessage } from "../helpers";
import { postData } from "../services/api";

import OtherPositionPopup from "../components/popup/OtherPositionPopup";
import { useTranslation } from "react-i18next";

const AddSuccessor = () => {
  setTitle("Succession Now | Add Successor");
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [position, setPosition] = useState([]);
  const [experience, setExperience] = useState([]);
  const [creditUnion, setCreditUnion] = useState([]);
  const [education, setEducation] = useState([]);
  const [performance, setPerformance] = useState([]);
  const [loyalty, setLoyalty] = useState([]);
  const [desire, setDesire] = useState([]);
  const [retirementWindow, setRetirementWindow] = useState([]);

  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [selectExperience, setSelectExperience] = useState("");
  const [selectCreditUnion, setSelectCreditUnion] = useState("");
  const [selectEducation, setSelectEducation] = useState("");
  const [selectPerformance, setSelectPerformance] = useState("");
  const [selectLoyalty, setSelectLoyalty] = useState("");
  const [selectDesire, setSelectDesire] = useState("");
  const [selectRetirementWindow, setSelectRetirementWindow] = useState("");
  const [additionalComments, setAdditionalComments] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [image, setImage] = useState("");
  const [showImage, setShowImage] = useState("");

  const [successors, setSuccessors] = useState([]);
  const [enabledOtherPositionPopup, setEnabledOtherPositionPopup] =
    useState(false);
  const [actionValue, setActionValue] = useState({});

  const [loyaltyPass, setLoyaltyPass] = useState(true);
  const [desirePass, setDesirePass] = useState(true);
  const [retirementPass, setRetirementPass] = useState(true);
  const [performancePass, setPerformancePass] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [isStep, setIsStep] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);

  const onCancel = async (e) => {
    setIsCancel(true);
    setDesignation("");
    setSelectExperience("");
    setSelectCreditUnion("");
    setSelectEducation("");
    setSelectPerformance("");
    setSelectLoyalty("");
    setSelectDesire("");
    setSelectRetirementWindow("");
    setAdditionalComments("");
    setIsCancel(false);
    navigate("/profiles");
  };

  useEffect(() => {
    // let successionPlanCount = 0;
    // if(user?.features && user?.features?.length>0){
    //   successionPlanCount = user?.features?.find(it => it?.code === 'succession-plans-per-year')?.inputValue;
    // }
    if (totalDataCount && totalDataCount >= 5) {
      navigate("/profiles");
    }
  }, [user, totalDataCount]);

  useEffect(() => {
    const loadSuccessors = async () => {
      try {
        const result = await postData("successor/list", {
          sortQuery: { created_at: 1 },
          is_interim: 2,
        });
        if (result.status && result.status === 200) {
          setTotalDataCount(result?.count);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadSuccessors();

    async function employeeData() {
      try {
        const result = await postData("employee/list", {
          sortQuery: { created_at: -1 },
          status: 1,
        });
        if (result.data) {
          setEmployees(
            result.data.map((items) => {
              let firstName = "";
              let lastName = "";
              if (!items?.first_name) {
                firstName = items?.name.split(/\s+/)[0];
                lastName = items?.name.split(/\s+/)[1];
              } else {
                firstName = items?.first_name;
                lastName = items?.last_name;
              }
              return {
                ...items,
                first_name: firstName,
                last_name: lastName,
              };
            })
          );
        } else {
          console.log("Employee list message", result.message);
        }
      } catch (error) {
        console.log("Error employee list catch", error.message);
      }
    }
    employeeData();

    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setPosition(
            result.data?.sort((a, b) => a.name.localeCompare(b.name))
          );
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();

    async function experienceData() {
      try {
        const result = await postData("experience/list", {
          sortQuery: { ordering: -1 },
          type: 1,
          status: 1,
          is_visabled: 1,
        });
        if (result.data) {
          setExperience(result.data);
        } else {
          console.log("Experience list message", result.message);
        }
      } catch (error) {
        console.log("Error Experience list catch", error.message);
      }
    }
    experienceData();

    async function creditUnionData() {
      try {
        const result = await postData("experience/list", {
          sortQuery: { ordering: -1 },
          type: 2,
          status: 1,
          is_visabled: 1,
        });
        if (result.data) {
          setCreditUnion(result.data);
        } else {
          console.log("Company list message", result.message);
        }
      } catch (error) {
        console.log("Error Company list catch", error.message);
      }
    }
    creditUnionData();

    async function educationList() {
      try {
        const result = await postData("education/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setEducation(result.data);
        } else {
          console.log("Education message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    educationList();

    async function performanceList() {
      try {
        const result = await postData("performance/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setPerformance(result.data);
        } else {
          console.log("Performance message", result.message);
        }
      } catch (error) {
        console.log("Error performance catch", error.message);
      }
    }
    performanceList();

    async function loyaltyList() {
      try {
        const result = await postData("loyalty/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setLoyalty(result.data);
        } else {
          console.log("Loyalty message", result.message);
        }
      } catch (error) {
        console.log("Error loyalty catch", error.message);
      }
    }
    loyaltyList();

    async function desireList() {
      try {
        const result = await postData("desire/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setDesire(result.data);
        } else {
          console.log("Desire message", result.message);
        }
      } catch (error) {
        console.log("Error desire catch", error.message);
      }
    }
    desireList();

    async function retirementWindowData() {
      try {
        const result = await postData("experience/list", {
          sortQuery: { created_at: 1 },
          type: 3,
          status: 1,
          is_visabled: 1,
        });
        if (result.data) {
          setRetirementWindow(result.data);
        } else {
          console.log("Retirement window list message", result.message);
        }
      } catch (error) {
        console.log("Error retirement window list catch", error.message);
      }
    }
    retirementWindowData();
  }, []);

  const onSubmit = async (e) => {
    if (designation === "others") {
      toastr.error(
        "You cannot select 'Others' directly. Please provide a value for 'Others' if you wish to proceed."
      );
      setDesignation("");
      return;
    }
    setIsAdd(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("designation", designation);
    formData.append("experience", selectExperience);
    formData.append("with_credit_union", selectCreditUnion);
    formData.append("education", selectEducation);
    formData.append("performance", selectPerformance);
    formData.append("loyalty", selectLoyalty);
    formData.append("desire_to_advance", selectDesire);
    formData.append("retirement_window", selectRetirementWindow);
    formData.append("additional_comments", additionalComments);
    formData.append("image", image);
    try {
      const result = await postData(
        "successor/create",
        formData
        // {
        //   name : name,
        //   designation:designation,
        //   experience : selectExperience,
        //   with_credit_union:selectCreditUnion,
        //   education:selectEducation,
        //   performance:selectPerformance,
        //   loyalty:selectLoyalty,
        //   desire_to_advance:selectDesire,
        //   retirement_window:selectRetirementWindow,
        //   additional_comments:additionalComments,
        //   image : image
        // }
      );
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsAdd(false);
        return navigate("/profiles");
      } else {
        toastr.error(result.message);
        setIsAdd(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsAdd(false);
    }
  };

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid image");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(actionValue).length > 0) {
      setPosition([...position, actionValue]);
      setDesignation(actionValue?._id);
    }
  }, [actionValue]);

  useEffect(() => {
    if (selectLoyalty) {
      const index = loyalty.findIndex((item) => item._id === selectLoyalty);
      if (index !== -1) {
        setLoyaltyPass(loyalty[index].isPass);
      }
    }
    if (selectDesire) {
      const index = desire.findIndex((item) => item._id === selectDesire);
      if (index !== -1) {
        setDesirePass(desire[index].isPass);
      }
    }
    if (selectRetirementWindow) {
      const index = retirementWindow.findIndex(
        (item) => item._id === selectRetirementWindow
      );
      if (index !== -1) {
        setRetirementPass(retirementWindow[index].isPass);
      }
    }
    if (selectPerformance) {
      const index = performance.findIndex(
        (item) => item._id === selectPerformance
      );
      if (index !== -1) {
        setPerformancePass(performance[index].isPass);
      }
    }
  }, [selectLoyalty, selectDesire, selectRetirementWindow, selectPerformance]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">
          {t("add_successor_for")} {user?.title}
        </div>
      </div>
      <form>
        <div className="relative grid grid-cols-4 gap-4">
          <div className="col-span-3">
            <div className="relative bg-white rounded shadow divide-y divide-slate-200 h-full">
              <div className="py-8 px-6 flex flex-col">
                <div className="space-y-4 mt-4">
                  <div className="relative w-1/2">
                    <Select
                      label={t("company_employees")}
                      labelClasses={"!text-xs"}
                      xPlacement={"left"}
                      selectedValue={""}
                      dropdownClass={"!w-60"}
                      search={true}
                      dropdownData={[
                        { _id: "", label: t("select"), value: "" },
                        ...employees.map((item) => ({
                          _id: item?._id,
                          label: item?.first_name + " " + item?.last_name,
                          value: item?.email,
                          position: item?.designation,
                        })),
                      ].filter((item) => item)}
                      getSelectedValue={(e) => {
                        setName(e?.label);
                        setDesignation(e?.position);
                      }}
                    />
                  </div>
                </div>
                <hr className="mt-3"></hr>
                <div className="space-y-4 mt-4">
                  <div className="relative">
                    <Input
                      label={t("full_name")}
                      labelClasses={"!text-xs"}
                      inputType={"text"}
                      inputPlaceholder={t("enter_name")}
                      inputValue={name}
                      setInput={setName}
                      requiredDisplay={true}
                    />
                  </div>
                  {/* ( && user?.isInvitedEmployee === 2) */}
                  <div className="flex items-start gap-4">
                    <div className="relative w-full">
                      <Select
                        label={t("current_position")}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={designation}
                        dropdownClass={"!w-full"}
                        search={true}
                        dropdownData={[
                          { _id: "", label: t("select_position"), value: "" },
                          ...position.map((item) => ({
                            _id: item?._id,
                            // label: `${item?.name}(${item?.tier_name || "N/A"})`,
                            label: item?.name,
                            value: item?._id,
                          })),
                          role &&
                          (role === "customer-admin" ||
                            role === "customer-subadmin" ||
                            role === "customer-user")
                            ? { _id: "others", label: t("other"), value: "" }
                            : "",
                        ].filter((item) => item)}
                        getSelectedValue={(e) => {
                          if (e._id === "others") {
                            setEnabledOtherPositionPopup(true);
                          }
                          setDesignation(e._id);
                        }}
                        requiredDisplay={true}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("years_of_senior_management_experience")}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={selectExperience}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          {
                            _id: " ",
                            label: t("select_experience"),
                            value: "",
                          },
                          ...experience.map((item) => ({
                            _id: item?._id,
                            label: item?.name,
                            value: item?._id,
                          })),
                        ]}
                        getSelectedValue={(e) => setSelectExperience(e._id)}
                        requiredDisplay={true}
                      />
                    </div>
                  </div>
                  <div className="flex items-start gap-4">
                    <div className="relative w-full">
                      <Select
                        label={t("years_with_company")}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={selectCreditUnion}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          {
                            _id: " ",
                            label: t("select_years_with_company"),
                            value: "",
                          },
                          ...creditUnion.map((item) => ({
                            _id: item?._id,
                            label: item?.name,
                            value: item?._id,
                          })),
                        ]}
                        getSelectedValue={(e) => setSelectCreditUnion(e._id)}
                        requiredDisplay={true}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("highest_level_education")}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={selectEducation}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_education"), value: "" },
                          ...education.map((item) => ({
                            _id: item?._id,
                            label: item?.name,
                            value: item?._id,
                          })),
                        ]}
                        getSelectedValue={(e) => setSelectEducation(e._id)}
                        requiredDisplay={true}
                      />
                    </div>
                  </div>
                  <div className="flex items-start gap-4">
                    <div className="relative w-full">
                      <Select
                        label={t("recent_performance_review")}
                        labelClasses={"!text-xs "}
                        dropdownButtonLabelClass={
                          !performancePass
                            ? "!text-rose-500 !font-semibold"
                            : ""
                        }
                        xPlacement={"left"}
                        selectedValue={selectPerformance}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          {
                            _id: " ",
                            label: t("select_performance"),
                            value: "",
                          },
                          ...performance.map((item) => ({
                            _id: item?._id,
                            label:
                              (item?.alt_name ? item?.alt_name : item?.name) +
                              " " +
                              (item.number ? "(" + item.number + ")" : ""),
                            value: item?._id,
                            isPass: item?.isPass,
                          })),
                        ]}
                        getSelectedValue={(e) => setSelectPerformance(e._id)}
                        requiredDisplay={true}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("loyalty_to_company")}
                        labelClasses={"!text-xs"}
                        dropdownButtonLabelClass={
                          !loyaltyPass ? "!text-rose-500 !font-semibold" : ""
                        }
                        xPlacement={"left"}
                        selectedValue={selectLoyalty}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_loyalty"), value: "" },
                          ...loyalty.map((item) => ({
                            _id: item?._id,
                            label: item?.name,
                            value: item?._id,
                            isPass: item?.isPass,
                          })),
                        ]}
                        getSelectedValue={(e) => setSelectLoyalty(e._id)}
                        requiredDisplay={true}
                      />
                    </div>
                  </div>
                  <div className="flex items-start gap-4">
                    <div className="relative w-full">
                      <Select
                        label={t("desire_to_advance")}
                        labelClasses={"!text-xs"}
                        dropdownButtonLabelClass={
                          !desirePass ? "!text-rose-500 !font-semibold" : ""
                        }
                        xPlacement={"left"}
                        selectedValue={selectDesire}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          {
                            _id: " ",
                            label: t("select_desire_to_advance"),
                            value: "",
                          },
                          ...desire.map((item) => ({
                            _id: item?._id,
                            label: item?.name,
                            value: item?._id,
                            isPass: item?.isPass,
                          })),
                        ]}
                        getSelectedValue={(e) => setSelectDesire(e._id)}
                        requiredDisplay={true}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("retirement_window")}
                        labelClasses={"!text-xs"}
                        dropdownButtonLabelClass={
                          !retirementPass ? "!text-rose-500 !font-semibold" : ""
                        }
                        xPlacement={"left"}
                        selectedValue={selectRetirementWindow}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          {
                            _id: " ",
                            label: t("select_retirement_window"),
                            value: "",
                          },
                          ...retirementWindow.map((item) => ({
                            _id: item?._id,
                            label: item?.name,
                            value: item?._id,
                            isPass: item?.isPass,
                          })),
                        ]}
                        getSelectedValue={(e) =>
                          setSelectRetirementWindow(e._id)
                        }
                        requiredDisplay={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-3 px-3 border-t border-slate-200">
                <div className="flex items-center justify-end gap-4">
                  <Button
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={
                      isCancel
                        ? "fa-light fa-spinner fa-spin"
                        : "fa-light fa-times"
                    }
                    buttonIconPosition={"left"}
                    buttonLabel={t("cancel")}
                    buttonClasses={
                      "!bg-slate-200 !text-slate-600 border border-slate-300"
                    }
                    functions={onCancel}
                    buttonHasLink={false}
                    buttonDisabled={isCancel}
                  />
                  <Button
                    buttonClasses={
                      successors && successors.length > 0
                        ? isStep && isStep >= 8
                          ? ""
                          : "!bg-rose-500"
                        : ""
                    }
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={
                      isAdd
                        ? "fa-light fa-spinner fa-spin"
                        : "fa-light fa-check"
                    }
                    buttonIconPosition={"left"}
                    buttonLabel={t("save")}
                    functions={(e) => {
                      if (successors && successors.length > 0) {
                        if (isStep && isStep >= 8) {
                          onSubmit(e);
                        } else {
                          toastr.error(NextStepMessage);
                        }
                      } else {
                        onSubmit(e);
                      }
                    }}
                    buttonHasLink={false}
                    buttonDisabled={isAdd}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 space-y-4">
            <div className="relative bg-white rounded shadow h-full flex flex-col">
              <div className="py-6 px-6 flex flex-col space-y-4 relative h-full max-h-[calc(100% - 40px)]">
                <div className="text-sm font-medium text-slate-500 mb-1">
                  {t("add_a_profile_picture_for_this_successor_optional")}
                </div>
                <div className="w-full overflow-hidden rounded-md flex items-center h-full">
                  <ButtonFile
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-image"}
                    buttonIconPosition={"left"}
                    buttonLabel={t("upload_image")}
                    buttonClasses={
                      "!h-full !w-full !absolute !opacity-0 !z-10 !top-0 !left-0"
                    }
                    functions={onImgChange}
                    accepts={"image/*"}
                  />
                  <Image
                    src={showImage}
                    alt={"Admin"}
                    width={"100%"}
                    effect={"blur"}
                    classes={"object-fit"}
                    id={"image1"}
                  />
                </div>
              </div>
              <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
                <ButtonFile
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-image"}
                  buttonIconPosition={"left"}
                  buttonLabel={t("upload_image")}
                  buttonClasses={
                    "!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white"
                  }
                  functions={onImgChange}
                  accepts={"image/*"}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      {enabledOtherPositionPopup && (
        <OtherPositionPopup
          isOpen={enabledOtherPositionPopup}
          setIsOpen={(val) => setEnabledOtherPositionPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          setDesignation={setDesignation}
        ></OtherPositionPopup>
      )}
    </>
  );
};
export default AddSuccessor;
