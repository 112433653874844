import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import toastr from "toastr";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router";
import { postData } from "../services/api";
import AlertPopup from "../components/popup/AlertPopup";
import { useTranslation } from "react-i18next";
import { useDebounce } from "../helpers/useDebounce";
import Select from "../components/form/Select";

const AddPosition = () => {
  const { id } = useParams();
  if (id) {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Edit Position"
    );
  } else {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Add Position"
    );
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState();
  const [isAdd, setIsAdd] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const role = localStorage.getItem("role");
  const [enabledAlertPopup, setEnabledAlertPopup] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTier, setSelectedTier] = useState("");
  const [tierList, setTierList] = useState("");
  const search = useDebounce(name, 500);
  const location = useLocation();

  useEffect(() => {
    // Position List
    const loadPosition = async () => {
      setLoading(true);
      try {
        const result = await postData("company-position/list", {
          keyword: search,
          limit: 1000000000,
          isGlobal: true,
        });
        if (result.data) {
          setPositions(result.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPosition();
  }, [search]);

  const createAcronym = (phrase) => {
    let string = phrase
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
    setShortName(string);
  };

  useEffect(() => {
    // Position details
    const loadPositionDetails = async () => {
      try {
        const result = await postData("position/details", {
          id: id,
        });
        if (result.status === 403) {
          if (
            role &&
            (role === "customer-admin" || role === "customer-subadmin")
          ) {
            return navigate("/position-alt");
          } else {
            return navigate("/positions");
          }
        } else {
          if (result.data) {
            setDetails(result.data);
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPositionDetails();
  }, [id]);

  useEffect(() => {
    const defaultTier =
      tierList &&
      Array?.isArray(tierList) &&
      tierList.length > 0 &&
      tierList?.filter((item) => item?.setDefault === 1)[0];
    setSelectedTier(details?.tierId || defaultTier?._id);
    setName(details?.name);
    setShortName(details?.short_name);
    if (id) {
      setSelectedTier(details?.tierId);
    } else {
      setSelectedTier(details?.tierId || defaultTier?._id);
    }
  }, [details, tierList, id]);

  const onCancel = async (e) => {
    if (role && (role === "customer-admin" || role === "customer-subadmin")) {
      if (location?.state === "/competencies-alt") {
        return navigate("/competencies-alt");
      } else {
        return navigate("/position-alt");
      }
    } else {
      return navigate("/positions");
    }
  };

  useEffect(() => {
    const onSubmit = async (e) => {
      setIsAdd(true);
      try {
        let path = "";
        let payload = "";
        if (id) {
          path = "position/update";
          payload = {
            id: id,
            name: name,
            short_name: shortName,
            // tierId: selectedTier,
          };
        } else {
          path = "position/create";
          payload = {
            name: name,
            short_name: shortName,
            // tierId: selectedTier,
          };
        }

        const result = await postData(path, payload);
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setEnabledAlertPopup(false);
          setIsAdd(false);
          if (
            (role && role === "customer-admin") ||
            role === "customer-subadmin"
          ) {
            if (location?.state === "/competencies-alt") {
              return navigate("/competencies-alt");
            } else {
              return navigate("/position-alt");
            }
          } else {
            return navigate("/positions");
          }
        } else {
          toastr.error(result.message);
          setIsAdd(false);
          setEnabledAlertPopup(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsAdd(false);
      }
    };
    if (actionValue && actionValue.button_type === "confirm") {
      onSubmit();
    }
  }, [actionValue]);

  const loadList = useCallback(async () => {
    try {
      const result = await postData("filter-item/list", {
        limit: 999999,
        type: "position-group",
      });
      if (result.status === 200) {
        setTierList(result.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex gap-3 items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">
          {id ? t("edit_position") : t("add_position")}
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 flex flex-col">
          <div className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="relative w-full group z-10">
                <Input
                  label={t("position")}
                  requiredDisplay={true}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={t("enter_position")}
                  inputValue={name}
                  setInput={(val) => {
                    setName(val);
                    createAcronym(val);
                  }}
                />
                {positions &&
                  Array?.isArray(positions) &&
                  positions?.length > 0 && (
                    <div className="hidden group-focus-within:block absolute top-full left-0 w-full mt-1">
                      <div className="relative bg-white border border-slate-100 shadow-md py-1 overflow-hidden rounded-lg">
                        <div className="overflow-auto scrollbar max-h-60">
                          {positions &&
                            Array?.isArray(positions) &&
                            positions?.length > 0 &&
                            positions?.map((item) => (
                              <>
                                <Button
                                  buttonLabel={
                                    item?.name + " - " + item?.short_name
                                  }
                                  buttonLabelClasses={"!text-xs"}
                                  buttonClasses={
                                    "w-full !h-8 !justify-start !bg-transparent hover:!bg-slate-100 !text-slate-500 hover:!text-600"
                                  }
                                  functions={(e) => {
                                    setName(item?.name);
                                    createAcronym(item?.name);
                                    setShortName(item?.short_name);
                                  }}
                                />
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="relative w-full">
                <Input
                  label={t("position_abbreviation")}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={t("enter_position_abreviation")}
                  inputValue={shortName}
                  setInput={setShortName}
                />
              </div>
              <div className="relative w-full">
                <Select
                  label={t("select_tier")}
                  labelClasses={"!text-xs !font-semibold"}
                  xPlacement={"left"}
                  selectedValue={selectedTier}
                  dropdownClass={"!w-full"}
                  dropdownData={[
                    {
                      _id: "",
                      label: t("select_tier"),
                      value: "",
                    },
                    ...(Array.isArray(tierList)
                      ? tierList.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        }))
                      : []),
                  ]}
                  getSelectedValue={(e) => {
                    setSelectedTier(e._id);
                  }}
                  requiredDisplay={true}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-5">
            <div className="flex items-center justify-end gap-4">
              <Button
                buttonLabelClasses=""
                buttonType={"button"}
                buttonIcon={
                  isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"
                }
                buttonIconPosition={"left"}
                buttonLabel={t("cancel")}
                buttonClasses={
                  "!bg-slate-200 !text-slate-600 border border-slate-300"
                }
                functions={onCancel}
                buttonHasLink={false}
                buttonDisabled={isCancel}
              />
              <Button
                buttonClasses=""
                buttonLabelClasses=""
                buttonType={"button"}
                buttonIcon={
                  isAdd ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"
                }
                buttonIconPosition={"left"}
                buttonLabel={t("save")}
                //functions={onSubmit}
                functions={() => {
                  if (!name) {
                    toastr.error(t("position_field_is_required"));
                  } else {
                    setEnabledAlertPopup(true);
                  }
                }}
                buttonHasLink={false}
                buttonDisabled={isAdd}
              />
            </div>
          </div>
        </div>
      </div>
      {enabledAlertPopup && (
        <AlertPopup
          isOpen={enabledAlertPopup}
          setIsOpen={(val) => setEnabledAlertPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></AlertPopup>
      )}
    </div>
  );
};

export default AddPosition;
