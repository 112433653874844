import React, { useState, useRef, useEffect, Fragment } from "react";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { postData } from "../../services/api";
import { allowedImageExtensions } from "../../helpers";
import ButtonFile from "../form/ButtonFile";
import Image from "../elements/Image";
import Input from "../form/Input";
import Checkbox from "../form/Checkbox";
import Button from "../form/Button";
import { setTitle } from "../../helpers/MetaTag";
import NoDataFound from "../NoDataFound";
import Searching from "../Searching";
import AlertPopup from "./AlertPopup";

const AddEditPositionPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  loadList = () => {},
  id,
}) => {
  if (id) {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Edit Position"
    );
  } else {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Add Position"
    );
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState();
  const [isAdd, setIsAdd] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const role = localStorage.getItem("role");
  const [enabledAlertPopup, setEnabledAlertPopup] = useState(false);
  const [actionValue, setActionValue] = useState({});

  const createAcronym = (phrase) => {
    let string = phrase
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
    setShortName(string);
  };

  useEffect(() => {
    // Position details
    const loadPositionDetails = async () => {
      try {
        const result = await postData("position/details", {
          id: id,
        });
        if (result.status === 403) {
          if (
            role &&
            (role === "customer-admin" || role === "customer-subadmin")
          ) {
            return navigate("/position-alt");
          } else {
            return navigate("/positions");
          }
        } else {
          if (result.data) {
            setDetails(result.data);
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPositionDetails();
  }, [id]);

  useEffect(() => {
    setName(details?.name);
    setShortName(details?.short_name);
  }, [details]);

  const onCancel = async (e) => {
    if (role && (role === "customer-admin" || role === "customer-subadmin")) {
      return navigate("/position-alt");
    } else {
      return navigate("/positions");
    }
  };

  useEffect(() => {
    const onSubmit = async (e) => {
      setIsAdd(true);
      try {
        let path = "";
        let payload = "";
        if (id) {
          path = "position/update";
          payload = {
            id: id,
            name: name,
            short_name: shortName,
          };
        } else {
          path = "position/create";
          payload = {
            name: name,
            short_name: shortName,
          };
        }

        const result = await postData(path, payload);
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setIsAdd(false);
          loadList();
          setIsOpen({ open: false, _id: null });
        } else {
          toastr.error(result.message);
          setIsAdd(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsAdd(false);
      }
    };
    if (actionValue && actionValue.button_type === "confirm") {
      onSubmit();
    }
  }, [actionValue]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  {id ? t("edit_position") : t("add_position")}
                </Dialog.Title>
                <div className="absolute top-2 right-2 z-10">
                  <Button
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-times"}
                    buttonIconPosition={"left"}
                    buttonLabel={""}
                    buttonClasses={
                      "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                    }
                    functions={() => {
                      setIsOpen({ _id: null, open: false });
                      setDetails("");
                      setName("");
                      setShortName("");
                    }}
                    buttonHasLink={false}
                  />
                </div>
                <div className="relative mt-4">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 flex flex-col">
                      <div className="space-y-4">
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-4">
                          <div className="relative w-full">
                            <Input
                              label={t("position")}
                              requiredDisplay={true}
                              labelClasses={"!text-xs"}
                              inputType={"text"}
                              inputPlaceholder={t("enter_position")}
                              inputValue={name}
                              setInput={(val) => {
                                setName(val);
                                createAcronym(val);
                              }}
                            />
                          </div>
                          <div className="relative w-full">
                            <Input
                              label={t("position_abbreviation")}
                              labelClasses={"!text-xs"}
                              inputType={"text"}
                              inputPlaceholder={t("enter_position_abreviation")}
                              inputValue={shortName}
                              setInput={setShortName}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end gap-4 mt-5">
                        <div className="flex items-center justify-end gap-4">
                          <Button
                            buttonClasses={
                              "bg-white border border-slate-200 !text-slate-400 ml-2"
                            }
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={"fa-regular fa-xmark"}
                            buttonIconPosition={"left"}
                            buttonLabel={t("cancel")}
                            functions={() => {
                              setIsOpen({ _id: null, open: false });
                              setDetails("");
                              setName("");
                              setShortName("");
                            }}
                            buttonHasLink={false}
                          />
                          <Button
                            buttonClasses=""
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={
                              isAdd
                                ? "fa-light fa-spinner fa-spin"
                                : "fa-light fa-check"
                            }
                            buttonIconPosition={"left"}
                            buttonLabel={t("save")}
                            //functions={onSubmit}
                            functions={() => {
                              if (!name) {
                                toastr.error(t("position_field_is_required"));
                              } else {
                                setEnabledAlertPopup(true);
                              }
                            }}
                            buttonHasLink={false}
                            buttonDisabled={isAdd}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {enabledAlertPopup && (
                    <AlertPopup
                      isOpen={enabledAlertPopup}
                      setIsOpen={(val) => setEnabledAlertPopup(val)}
                      getActionValue={(obj) => {
                        setActionValue(obj);
                      }}
                    ></AlertPopup>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddEditPositionPopup;
