import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import Search from "../components/form/Search";
import Table from "../components/elements/table/Table";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../helpers";
import toastr from "toastr";
import CmsTab from "../shared/CmsTab";
import { useSelector } from "react-redux";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import AddEditCompanyPosition from "../components/popup/AddEditCompanyPosition";

const PositionAlt = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Positions"
  );
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [positions, setPositions] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedTier, setSelectedTier] = useState("");
  const [tierList, setTierList] = useState("");
  const [isOpen, setIsopen] = useState({
    _id: null,
    open: false,
  });

  const [positionFor, setPositionFor] = useState(
    user &&
      Array.isArray(user?.company_info) &&
      user?.company_info.length > 0 &&
      user?.company_info[0]?._id
  );

  const extraData = [
    {
      _id: 2,
      label: t("edit"),
      icon: "fa-pen-to-square",
      link: "/",
      type: "button",
      standout: false,
      buttonType: "edit-user-info",
      isVisabled: 1,
      ordering: 1,
    },
  ];

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "status") {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("position/status-change", {
            id: actionValue?.row_id,
            status: actionValue?.current_status,
          });
          if (statusData.data) {
            setPositions(
              positions.map((it) => {
                if (it?._id === statusData.data?._id) {
                  return {
                    ...it,
                    status: statusData.data?.status,
                  };
                } else {
                  return it;
                }
              })
            );
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("position/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = positions.findIndex(
              (item) => item._id === actionValue?.row_id
            );
            if (index !== -1) {
              positions.splice(index, 1);
              setPositions(
                positions.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    } else if (actionValue && actionValue.button_type === "edit-user-info") {
      setIsopen({ open: true, _id: actionValue?.row_id });
    }
    //console.log('actionValue',actionValue)
  }, [actionValue]);

  const loadPosition = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("company-position/list", {
        keyword: keyword,
        limit: limit,
        offset: keyword ? 0 : offset,
        sortQuery: sortQuery,
        status: status,
        tierId: selectedTier,
        companyId:
          positionFor ||
          user?._id ||
          (user &&
            Array.isArray(user?.company_info) &&
            user?.company_info.length > 0 &&
            user?.company_info[0]?._id),
      });
      if (result.data) {
        setPositions(result.data);
      }
      setTotalDataCount(result.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [
    keyword,
    limit,
    offset,
    sortQuery,
    status,
    isUpdate,
    positionFor,
    selectedTier,
  ]);

  useEffect(() => {
    loadPosition();
  }, [loadPosition]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    if (keyword) {
      setOffset(0);
    }
  }, [limit, totalDataCount, keyword]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const tableHeadData = [
    {
      _id: 1,
      width: 40,
      name: t("name"),
      value: "name",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 2,
      width: 15,
      name: t("short_name"),
      value: "short_name",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    // {
    //   _id: 3,
    //   width: 20,
    //   name: t("created_date"),
    //   value: "created_at",
    //   align: "left",
    //   isSort: false,
    //   isFilter: false,
    // },
    // {
    //   _id: 4,
    //   width: 40,
    //   name: "Tier Name",
    //   value: "tier",
    //   align: "left",
    //   isSort: false,
    //   isFilter: false,
    // },
    {
      _id: 5,
      width: 15,
      name: t("status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 6,
      width: 10,
      name: t("action"),
      value: "",
      align: "right",
      isSort: false,
      isFilter: false,
    },
  ];

  const handleOnDragEnd = async (result) => {
    //console.log("result",result);
    const items = reorder(
      positions,
      result.source.index,
      result.destination.index
    );
    setPositions(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const tableData =
    positions &&
    Array?.isArray(positions) &&
    positions?.length > 0 &&
    positions?.map((value, key) => {
      return {
        _id: value?._id,
        rowData: [
          { _id: 1, width: 40, type: "text", data: value?.name },
          { _id: 2, width: 15, type: "text", data: value?.short_name },
          // {
          //   _id: 3,
          //   width: 20,
          //   type: "text",
          //   data:
          //     value && value?.created_at
          //       ? moment(new Date(value?.created_at)).format("Do MMM YYYY")
          //       : "N/A",
          // },
          // {
          //   _id: 4,
          //   width: 15,
          //   type: "text",
          //   data: value?.tier_name || "N/A",
          // },
          {
            _id: 5,
            width: 15,
            type: "status",
            statusLabel:
              value?.status && value?.status === 1
                ? t("active")
                : t("deactivated"),
            statusType:
              value?.status && value?.status === 1 ? "success" : "warning",
          },
          role && (role === "customer-admin" || role === "customer-subadmin")
            ? {
                _id: 6,
                width: 10,
                type: "action",
                statusLabel:
                  value?.status && value?.status === 1
                    ? t("active")
                    : t("deactivated"),
                statusType:
                  value?.status && value?.status === 1 ? "success" : "warning",
                data: [{ "edit-user-info": 1 }],
              }
            : "",
        ],
      };
    });

  const loadList = useCallback(async () => {
    try {
      const result = await postData("filter-item/list", {
        limit: 999999,
        type: "position-group",
      });
      if (result.status === 200) {
        setTierList(result.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("positions") + " - " + user?.company_name}
        </div>
        <div className="sm:ml-auto flex justify-end items-center space-x-4">
          <Button
            buttonType={"button"}
            buttonIcon={"fa-light fa-plus"}
            buttonIconPosition={"left"}
            buttonLabel={t("add_positions")}
            buttonHasLink={false}
            functions={() => setIsopen({ _id: null, open: true })}
          />
        </div>
      </div>
      <div className="relative flex gap-3 items-center mb-3 flex-wrap">
        {/* <div className="relative">
          <Select
            xPlacement={"right"}
            dropdownButtonClass={"!bg-white"}
            transitionClass={"!w-auto"}
            selectedValue={positionFor}
            dropdownData={[
              { _id: 1, label: "Global Positions", value: "" },
              user?.company_name
                ? {
                    _id: user?._id,
                    label: `${user?.company_name} Positions`,
                    value: user?._id,
                  }
                : "",
            ]}
            getSelectedValue={(e) => setPositionFor(e.value)}
          />
        </div> */}
        <div className="relative w-52">
          <Search
            placeholder={t("search")}
            setInput={setKeyword}
            valueReset={() => {
              setOffset(0);
              // setCurrentPage(0);
              // setSelectedRow([]);
            }}
          />
        </div>
        {/* <div className="relative w-60">
          <Select
            xPlacement={"left"}
            selectedValue={selectedTier}
            dropdownClass={"!w-full"}
            dropdownData={[
              {
                _id: "",
                label: t("select_tier"),
                value: "",
              },
              ...(Array.isArray(tierList)
                ? tierList.map((item) => ({
                    _id: item?._id,
                    label: item?.name,
                    value: item?._id,
                  }))
                : []),
            ]}
            getSelectedValue={(e) => {
              setSelectedTier(e._id);
            }}
          />
        </div> */}
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow overflow-hidden">
          <Table
            tableData={tableData}
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            // editUrl={"/edit-position"}
            extraData={extraData}
            tableImage={false}
            isStatusUpdate={true}
            isDeleteEnabled={true}
            isDraggable={true}
            getActionValue={(obj) => setActionValue(obj)}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <div className="flex justify-center sm:justify-between my-4">
          <div>
            <p>
              {t("showing")}: {startDataCount} to {endDataCount} of{" "}
              {totalDataCount}
            </p>
          </div>
          <ReactPaginate
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={noOfPage}
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      ) : (
        <div className="flex justify-center sm:justify-between my-4">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {isOpen?.open && (
        <AddEditCompanyPosition
          isOpen={isOpen?.open}
          id={isOpen?._id}
          setIsOpen={setIsopen}
          loadPositionList={loadPosition}
        />
      )}
    </>
  );
};

export default PositionAlt;
