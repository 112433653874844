import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import Search from "../components/form/Search";
import Table from "../components/elements/table/Table";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../helpers";
import toastr from "toastr";
import CmsTab from "../shared/CmsTab";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import Pagination from "../components/Common/Pagination";
import { Tooltip } from "react-tooltip";
import AddEditConcernHeadingsPopup from "../components/popup/AddEditConcernHeadingsPopup";
import { getStatusFilter } from "../constant/Constant";
import { useDebounce } from "../helpers/useDebounce";
import DefaultHeadingManagePopup from "../components/popup/DefaultHeadingManagePopup";
import MultipleButtonAction from "../components/elements/multipleButtonAction/MultipleButtonAction";

const ConcernHeadings = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Concern Heading"
  );
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const status_filter = getStatusFilter(t);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 500);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [headings, setHeadings] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const [enabledDefaultHeadingPopup, setEnabledDefaultHeadingPopup] =
    useState(false);
  const [isOpen, setIsopen] = useState({
    _id: null,
    open: false,
  });
  const [tierList, setTierList] = useState([]);
  const [selectedTier, setSelectedTier] = useState("");

  const extraData = [
    {
      _id: 1,
      label: t("edit"),
      icon: "fa-pen-to-square",
      link: "/",
      type: "button",
      standout: false,
      buttonType: "edit-user-info",
      isVisabled: 1,
      ordering: 1,
    },
  ];

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "status") {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("heading/status-change", {
            id: actionValue?.row_id,
            status: actionValue?.current_status,
          });
          if (statusData.data) {
            setHeadings(
              headings.map((it) => {
                if (it?._id === statusData.data?._id) {
                  return {
                    ...it,
                    status: statusData.data?.status,
                  };
                } else {
                  return it;
                }
              })
            );
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("heading/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = headings.findIndex(
              (item) => item._id === actionValue?.row_id
            );
            if (index !== -1) {
              headings.splice(index, 1);
              setHeadings(
                headings.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    } else if (actionValue && actionValue.button_type === "edit-user-info") {
      setIsopen({ open: true, _id: actionValue?.row_id });
    }
    //console.log('actionValue',actionValue)
  }, [actionValue]);

  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("heading/list", {
        keyword: search,
        limit: limit,
        offset: offset,
        sortQuery: sortQuery,
        status: status,
        type: "concern",
        isGlobal: true,
        tierId: selectedTier,
      });
      if (result.data) {
        setHeadings(result.data);
      }
      setTotalDataCount(result.count);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [search, limit, offset, sortQuery, status, isUpdate, selectedTier]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const tableData =
    headings &&
    Array?.isArray(headings) &&
    headings.length > 0 &&
    headings.map((value, key) => {
      return {
        _id: value?._id,
        rowData: [
          { _id: 1, type: "text", data: value?.name || "N/A" },
          { _id: 2, type: "text", data: value?.description || "N/A" },
          {
            _id: 3,
            type: "text",
            data:
              value && value?.created_at
                ? moment(new Date(value?.created_at)).format("ll")
                : "N/A",
          },
          {
            _id: 4,
            type: "status",
            statusLabel:
              value?.status && value?.status === 1 ? "Active" : "Deactivated",
            statusType:
              value?.status && value?.status === 1 ? "success" : "warning",
          },
          {
            _id: 5,
            type: "action",
            statusLabel:
              value?.status && value?.status === 1 ? "Active" : "Deactivated",
            statusType:
              value?.status && value?.status === 1 ? "success" : "warning",
            data: [{ "edit-user-info": 1 }],
          },
        ],
      };
    });

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    setOffset(newOffset);
    setCurrentPage(event.selected);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    setSelectedRow([]);
  };

  const tableHeadData = [
    {
      _id: 1,
      name: t("areas_of_concern"),
      value: "name",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 2,
      name: t("description"),
      value: "description",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 3,
      name: t("created"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 6,
      width: 15,
      name: t("status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: true,
      filterData: status_filter || [],
      onFilter: (data) => {
        if (data && Array.isArray(data) && data.length === 1) {
          setStatus(data[0]?.value);
        } else {
          setStatus("");
        }
        setOffset(0);
      },
    },
    {
      _id: 7,
      name: t("action"),
      value: "",
      align: "right",
      isSort: false,
      isFilter: false,
    },
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(
      headings,
      result.source.index,
      result.destination.index
    );
    setHeadings(items);
    const res = await postData("performance/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const loadTierList = useCallback(async () => {
    try {
      const result = await postData("filter-item/list", {
        sortQuery: { ["created_at"]: -1 },
        type: "position-group",
      });
      if (result.status === 200) {
        setTierList(result.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    loadTierList();
  }, [loadTierList]);

  return (
    <>
      <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
        {role && role !== "master-admin" && (
          <div className="relative ml-auto flex justify-start items-center">
            <CmsTab xPlacement={"left"} />
          </div>
        )}
        <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
          <div className="text-2xl font-bold">
            {t("possible_areas_of_concern")}
          </div>
          <div className="sm:ml-auto flex justify-end items-center space-x-2">
            <div
              className="relative"
              data-tooltip-id={`manageDefaultbutton${12}`}
              data-tooltip-place="bottom"
            >
              <Button
                buttonType={"button"}
                buttonIcon={"fa-light fa-list-check"}
                buttonIconPosition={"left"}
                buttonLabel={t("manage_default")}
                buttonHasLink={false}
                functions={() => setEnabledDefaultHeadingPopup(true)}
              />
              <Tooltip
                id={`manageDefaultbutton${12}`}
                className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
                render={() => (
                  <div className="gap-x-1 gap-y-1">
                    <div className="text-[13px] font-normal leading-none flex items-center">
                      <div className="font-semibold">
                        Manage Default Areas of Concern
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
            <div
              className="relative"
              data-tooltip-id={`button${1}`}
              data-tooltip-place="bottom"
            >
              <Button
                buttonType={"button"}
                buttonIcon={"fa-light fa-plus"}
                buttonIconPosition={"left"}
                buttonLabel={t("add")}
                buttonHasLink={false}
                functions={() => setIsopen({ _id: null, open: true })}
              />
              <Tooltip
                id={`button${1}`}
                className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
                render={() => (
                  <div className="gap-x-1 gap-y-1">
                    <div className="text-[13px] font-normal leading-none flex items-center">
                      <div className="font-semibold">
                        Add New Possible Areas of Concern
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <div className="relative flex gap-3 items-center mb-3 flex-wrap">
          <div className="relative">
            <Select
              xPlacement={"left"}
              dropdownButtonClass={"!bg-white"}
              selectedValue={limit}
              dropdownClass={"w-auto"}
              dropdownData={[
                { _id: 1000000, label: t("all_items"), value: 1000000 },
                ...[
                  { _id: 10, label: "10 " + t("items"), value: 10 },
                  { _id: 20, label: "20 " + t("items"), value: 20 },
                  { _id: 30, label: "30 " + t("items"), value: 30 },
                  { _id: 50, label: "50 " + t("items"), value: 50 },
                  { _id: 100, label: "100 " + t("items"), value: 100 },
                ],
              ]}
              getSelectedValue={(e) => {
                setLimit(e.value);
                setOffset(0);
                setCurrentPage(0);
                setSelectedRow([]);
              }}
            />
          </div>
          <div className="relative w-48">
            <Search
              placeholder={t("search")}
              setInput={setKeyword}
              valueReset={() => {
                setOffset(0);
                setCurrentPage(0);
                setSelectedRow([]);
              }}
            />
          </div>
          <div className="realtive  w-48">
            <Select
              xPlacement={"left"}
              selectedValue={selectedTier}
              dropdownClass={"!w-auto"}
              dropdownData={[
                {
                  _id: "",
                  label: "Select Tier",
                  value: "",
                },
                ...tierList.map((item, index) => ({
                  _id: item?._id,
                  label: item?.name,
                  value: item?._id,
                })),
              ]}
              getSelectedValue={(e) => {
                setSelectedTier(e?._id);
              }}
              requiredDisplay={true}
            />
          </div>
          <div className="relative">
            <MultipleButtonAction
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              list={headings}
              setList={setHeadings}
              loadList={loadList}
              API_URL_STATUS={"heading/status-change"}
              API_URL_DELETE={"heading/delete"}
            />
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="w-full bg-white rounded-md shadow overflow-hidden">
            <Table
              tableData={tableData}
              tableHeadData={tableHeadData}
              totalRecord={totalDataCount}
              loading={loading}
              getSortValue={setSortValue}
              getSortKey={setSortKey}
              // editUrl={"/edit-concern-heading"}
              extraData={extraData}
              isStatusUpdate={true}
              isDeleteEnabled={true}
              detailsUrl={"/options"}
              isDraggable={false}
              getActionValue={(obj) => setActionValue(obj)}
              tableImage={false}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              selectable={true}
            />
          </div>
        </DragDropContext>
        {noOfPage > 1 ? (
          <Pagination
            handlePageClick={handlePageClick}
            noOfPage={noOfPage}
            startDataCount={startDataCount}
            endDataCount={endDataCount}
            count={totalDataCount}
            forcePage={currentPage}
          />
        ) : (
          <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
            {totalDataCount > 0 ? (
              <div>
                <p>
                  {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                  {totalDataCount}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      {isOpen?.open && (
        <AddEditConcernHeadingsPopup
          isOpen={isOpen?.open}
          id={isOpen?._id}
          setIsOpen={setIsopen}
          loadList={loadList}
          type={"concern"}
        />
      )}
      {enabledDefaultHeadingPopup && (
        <DefaultHeadingManagePopup
          isOpen={enabledDefaultHeadingPopup}
          setIsOpen={(val) => setEnabledDefaultHeadingPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          type={"concern"}
        />
      )}
    </>
  );
};

export default ConcernHeadings;
