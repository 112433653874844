import React, { useState, useEffect, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Select from "../form/Select";
import Searching from "../Searching";
import NoDataFound from "../NoDataFound";
import Textarea from "../form/Textarea";
import Checkbox from "../form/Checkbox";
import "flatpickr/dist/flatpickr.css";
import OtherRatingOptionPopup from "./OtherRatingOptionPopup";
import SurveyConfirmationPopup from "./SurveyConfirmationPopup";
import { useSelector } from "react-redux";

import { postData } from "../../services/api";
import toastr from "toastr";

import { useTranslation } from "react-i18next";

const SurveyPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  surveyFor = "boardmember",
  isConfirmation,
  getActionValue = () => {},
}) => {
  const { i18n, t } = useTranslation();
  const flatPicker = useRef(null);
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [isConfirm, setIsConfirm] = useState(false);
  const [reatingOptions, setReatingOptions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [pastIndex, setPastIndex] = useState(0);
  const [curentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [enabledOtherRatingOptionPopup, setEnabledOtherRatingOptionPopup] =
    useState(false);
  const [enabledConfirmationSurveyPopup, setEnabledConfirmationSurveyPopup] =
    useState(false);
  const [actionValue, setActionValue] = useState({});
  const [currentQuestionId, setCurrentQuestionId] = useState("");
  const [curentFieldType, setCurrentFieldType] = useState("");
  const [addonsAnswers, setAddonsAnswers] = useState([
    {
      label: "",
      value: "",
      description: "",
    },
  ]);

  const [isShow, setIsShow] = useState(true);
  const [isAdd, setIsAdd] = useState(false);
  const [label, setLabel] = useState(null);
  const [value, setValue] = useState(null);
  const [description, setDescription] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const closeModal = () => {
    //setIsOpen(false);
    try {
      getActionValue({
        button_type: "update-survey",
        row_id: "",
        current_status: "",
        reatingOptions: reatingOptions,
        answers: answers,
      });
      setIsOpen(false);
    } catch (error) {
      toastr.error(error.message);
    }
  };

  useEffect(() => {
    async function employeeRatingOptionList() {
      try {
        const result = await postData("employee-rating-option/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          limit: 50,
          type: surveyFor,
        });
        if (result.data) {
          let selectedIndex = 0;
          if (data) {
            let perItem = Number(100 / result?.data?.length);
            let questionCount =
              data.length > 0 &&
              data?.filter(
                (it) => it?.answerId || it?.ids?.length > 0 || it?.isNone
              )?.length;
            if (questionCount > Number(result.data?.length - 1)) {
              selectedIndex = 0;
              setCurrentIndex(0);
              setNextIndex(0);
            } else {
              selectedIndex = Number(questionCount);
              setCurrentIndex(Number(questionCount));
              setNextIndex(Number(questionCount));
            }
            setProgressPercentage(
              Number(Math.round(perItem.toFixed(2) * Number(questionCount)))
            );
          }
          if (selectedIndex === Number(result?.data?.length - 1)) {
            setIsDisabled(false);
          }
          setReatingOptions(
            result.data.map((item, index) => {
              return {
                ...item,
                isOpen: index === selectedIndex ? true : false,
              };
            })
          );
          setAnswers(
            result.data.map((value) => {
              let _question =
                data.length > 0 &&
                data?.filter((it) => it?.questionId === value?.question?._id);
              if (_question.length > 0) {
                let isNone = (_question[0]?.questionInfo?.question?.fieldType ==="checkbox" && (_question[0]?.ids?.length === 0 || _question[0]?.ids === undefined)) ? true : false;
                return {
                  ..._question[0],
                  questionInfo: value,
                  isNone: isNone,
                };
              } else {
                return {
                  questionId: value?.question?._id,
                  answerId: null,
                  answerLabel: null,
                  rating: 0,
                  comments: null,
                  ids: [],
                  isNone: (value?.question?.fieldType ==="checkbox")?true:false,
                  questionInfo: value,
                };
              }
            })
          );
        } else {
          console.log("Employee rating message", result.message);
        }
      } catch (error) {
        console.log("Error employee rating catch", error.message);
      }
    }
    employeeRatingOptionList();
  }, [data]);  

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsConfirm(true);
    try {
      let checkAnswer = answers?.filter(
        (it) => it?.answerId !== null || it?.ids?.length > 0 || it?.isNone
      )?.length;
      if (checkAnswer === reatingOptions.length) {
        getActionValue({
          button_type: "update-survey",
          row_id: "",
          current_status: "",
          reatingOptions: reatingOptions,
          answers: answers,
          autoSave: true,
        });
        setIsOpen(false);
        setIsConfirm(false);
      } else {
        //toastr.error("Please select at least one option before proceeding.",{autoClose: 2000});
        setIsConfirm(false);
      }
    } catch (error) {
      toastr.error(error.message);
    }
  };

  const changeIndex = async (type, index) => {
    setLoading(true);
    let currentItem = answers.filter((it, idx) => idx === curentIndex);
    let perItem = Number(100 / reatingOptions.length);
    if (
      currentItem &&
      currentItem.length > 0 &&
      (!currentItem[0].answerId || !currentItem[0]?.ids?.length === 0) &&
      type === "next"
    ) {
      toastr.error("First choose any rating", { autoClose: 2000 });
    } else {
      if (type === "past") {
        let _pastIndex = Number(index - 1);
        let _nextIndex = Number(index);
        setReatingOptions(
          reatingOptions.map((item, index) => {
            return {
              ...item,
              isOpen: index === _pastIndex ? true : false,
            };
          })
        );
        setPastIndex(_pastIndex);
        setNextIndex(_nextIndex);
        setCurrentIndex(_pastIndex);
        let _questionCount =
          data.length > 0 &&
          data?.filter(
            (it) => it?.answerId || it?.ids?.length > 0 || it?.isNone
          )?.length;
        if (_questionCount > Number(reatingOptions?.length - 1)) {
          setProgressPercentage(100);
        } else {
          setProgressPercentage(
            Number(Math.round(perItem.toFixed(2) * _pastIndex))
          );
        }
        setIsDisabled(true);
        setLoading(false);
      } else if (type === "next") {
        let _pastIndex = Number(index);
        let _nextIndex = Number(index + 1);
        setReatingOptions((reatingOptions) =>
          reatingOptions.map((item, idx) => {
            return {
              ...item,
              isOpen: idx === _nextIndex ? true : false,
            };
          })
        );
        setPastIndex(_pastIndex);
        setNextIndex(_nextIndex);
        setCurrentIndex(_nextIndex);
        let _questionCount =
          data.length > 0 &&
          data?.filter(
            (it) => it?.answerId || it?.ids?.length > 0 || it?.isNone
          )?.length;
        if (_questionCount > Number(reatingOptions?.length - 1)) {
          setProgressPercentage(100);
        } else {
          setProgressPercentage(
            Number(Math.round(perItem.toFixed(2) * _nextIndex))
          );
        }
        if (_nextIndex === Number(reatingOptions.length - 1)) {
          setIsDisabled(false);
        }
        setLoading(false);
      }
    }
  };

  const handelUpdate = async (question_id, answer_id, rating, label, index) => {
    setAnswers((answers) =>
      answers.map((item, i) => {
        if (item?.questionId === question_id) {
          if (answer_id === "comments") {
            return {
              ...item,
              comments: rating,
            };
          } else {
            return {
              ...item,
              answerId: answer_id,
              answerLabel: label,
              rating: rating,
            };
          }
        } else {
          return {
            ...item,
            isNone: (item?.questionInfo?.question?.fieldType ==="checkbox" && (item?.ids?.length === 0 || item?.ids === undefined))?true:false,
          };
        }
      })
    );
  };

  const updateHandler = (_id, event, question_id) => {
    if (event) {
      setAnswers((answers) =>
        answers.map((item, i) => {
          if (_id === "none") {
            return {
              ...item,
              ids: [],
              isNone: true,
            };
          } else {
            if (item?.questionId === question_id) {
              return {
                ...item,
                ids: item?.ids?.length > 0 ? [...item?.ids, _id] : [_id],
                isNone: false,
              };
            } else {
              return item;
            }
          }
        })
      );
    } else {
      setAnswers((answers) =>
        answers.map((item, i) => {
          if (item?.questionId === question_id) {
            return {
              ...item,
              ids: item?.ids?.filter((_it) => _it !== _id),
              isNone: false,
            };
          } else {
            return item;
          }
        })
      );
    }
  };

  useEffect(() => {
    // if(actionValue && actionValue.button_type === "add_other_answer"){
    //   setReatingOptions(
    //     reatingOptions => reatingOptions.map((item,idx) => {
    //       if(item.question?._id === actionValue.row_id){
    //         return {
    //           ...item,
    //           question: {
    //             ...item.question,
    //             answers: [
    //               ...item.question?.answers,actionValue.result
    //             ]
    //           }
    //         }
    //       }else{
    //         return item;
    //       }
    //     })
    //   );
    // }
    if (actionValue && actionValue.button_type === "confirmation-survay") {
      if (actionValue.current_status === 1) {
        getActionValue({
          button_type: "update-survey",
          row_id: "",
          current_status: "",
          reatingOptions: reatingOptions,
          answers: answers,
          autoSave: true,
        });
        setIsOpen(false);
      } else if (actionValue.current_status === 2) {
        // getActionValue({
        //   button_type: "update-survey",
        //   row_id: "",
        //   current_status: "" ,
        //   reatingOptions : reatingOptions,
        //   answers : answers,
        //   autoSave : false
        // });
        setIsOpen(false);
      }
    }
  }, [actionValue]);

  const isDescriptionEmpty = (obj) => {
    return obj.description === "";
  };

  const validateDescriptions = (array) => {
    return array.every(isDescriptionEmpty);
  };

  const onAddAnswer = async () => {
    setIsAdd(true);
    if (validateDescriptions(addonsAnswers)) {
      toastr.error(t("all_field_is_empty"));
      setIsAdd(false);
    } else {
      try {
        const result = await postData(
          "employee-rating-option/add-individual-row",
          {
            questionId: currentQuestionId,
            answers: addonsAnswers,
            // label : label,
            // value:value,
            // description : description
          }
        );
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setReatingOptions((reatingOptions) =>
            reatingOptions.map((item, idx) => {
              if (item.question?._id === currentQuestionId) {
                return {
                  ...item,
                  question: {
                    ...item.question,
                    answers: [...item.question?.answers, ...result?.data],
                  },
                };
              } else {
                return item;
              }
            })
          );
          setAddonsAnswers([
            {
              label: "",
              value: "",
              description: "",
            },
          ]);
          // setLabel(null);
          // setValue(null);
          // setDescription(null);
          //setIsShow(false);
          //getActionValue({ button_type : 'add_other_answer',row_id : data?.questionId,result : result?.data});
          //setIsOpen(false)
          setIsAdd(false);
        } else {
          toastr.error(result.message);
          setIsAdd(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setLoading(false);
      }
    }
  };

  const addRow = async () => {
    setAddonsAnswers((addonsAnswers) => [
      ...addonsAnswers,
      {
        label: "",
        value: "",
        description: "",
      },
    ]);
  };

  const removeRow = async (index) => {
    setAddonsAnswers((addonsAnswers) =>
      addonsAnswers.filter((item, i) => i !== index)
    );
  };

  const handelOtherUpdate = async (field, val, index) => {
    if (field === "label") {
      setAddonsAnswers((addonsAnswers) =>
        addonsAnswers.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              label: val,
            };
          } else {
            return item;
          }
        })
      );
    } else if (field === "value") {
      setAddonsAnswers((addonsAnswers) =>
        addonsAnswers.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              value: val,
            };
          } else {
            return item;
          }
        })
      );
    } else if (field === "description") {
      setAddonsAnswers((addonsAnswers) =>
        addonsAnswers.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              description: val,
            };
          } else {
            return item;
          }
        })
      );
    }
  };

  const handelEveryAnswerUpdate = async (field, val, questionId, answerId) => {
    if (field === "description") {
      setReatingOptions((reatingOptions) =>
        reatingOptions.map((item, idx) => {
          if (item.question?._id === questionId) {
            return {
              ...item,
              question: {
                ...item.question,
                answers: [
                  ...item.question?.answers.map((ans) => {
                    if (ans._id === answerId) {
                      return {
                        ...ans,
                        description: val,
                      };
                    } else {
                      return ans;
                    }
                  }),
                ],
              },
            };
          } else {
            return item;
          }
        })
      );
    }
  };

  const saveItem = async (field, questionId, answer_id) => {
    let ans = reatingOptions.find((item) => item?.question?._id === questionId)
      ?.question.answers;
    let value = "";
    if (ans && ans?.length > 0) {
      value = ans.find((item) => item?._id === answer_id)?.description;
    }
    try {
      const result = await postData(
        "employee-rating-option/update-individual-row",
        {
          questionId: questionId,
          answerId: answer_id,
          field: field,
          value: value,
        }
      );
      if (result.status && result.status === 200) {
        toastr.success(result.message);
      } else {
        toastr.error(result.message);
      }
    } catch (error) {
      toastr.error(error.message);
    }
  };

  const deleteItem = async (questionId, answerId) => {
    let ans = reatingOptions.find((item) => item?.question?._id === questionId)
      ?.question.answers;
    let value = "";
    if (ans && ans?.length > 0) {
      value = ans.find((item) => item?._id === answerId)?.description;
    }
    try {
      const result = await postData(
        "employee-rating-option/delete-individual-row",
        {
          questionId: questionId,
          answerId: answerId,
        }
      );
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setReatingOptions((reatingOptions) =>
          reatingOptions.map((item, idx) => {
            if (item.question?._id === questionId) {
              return {
                ...item,
                question: {
                  ...item.question,
                  answers: [
                    ...item.question?.answers
                      .map((ans) => {
                        if (ans?._id !== answerId) {
                          return ans;
                        }
                      })
                      .filter((it) => it),
                  ],
                },
              };
            } else {
              return item;
            }
          })
        );
      } else {
        toastr.error(result.message);
      }
    } catch (error) {
      toastr.error(error.message);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {Number(reatingOptions.length - 1) === nextIndex
                      ? t("final_input")
                      : surveyFor === "boardmember"
                      ? t("board_effectiveness")
                      : t("executive_audit_360")}
                  </Dialog.Title>

                  <div className="relative border-y border-slate-200 py-2 my-2">
                    <div className="flex justify-between mb-1">
                      <span
                        className={
                          "text-base font-medium " +
                          (progressPercentage > 90
                            ? "text-teal-600"
                            : progressPercentage > 50
                            ? "text-amber-600"
                            : "text-rose-600")
                        }
                      >
                        {t("steps")}: {Number(curentIndex + 1)}
                      </span>
                      <span
                        className={
                          "text-sm font-medium " +
                          (progressPercentage > 90
                            ? "text-teal-600"
                            : progressPercentage > 50
                            ? "text-amber-600"
                            : "text-rose-600")
                        }
                      >
                        {progressPercentage}%
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div
                        className={
                          (progressPercentage > 90
                            ? "bg-teal-600"
                            : progressPercentage > 50
                            ? "bg-amber-600"
                            : "bg-rose-600") + " h-2.5 rounded-full"
                        }
                        style={{ width: progressPercentage + "%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="relative space-y-5">
                    {reatingOptions && reatingOptions.length > 0 ? (
                      reatingOptions.map((item, index) => {
                        let q_idx = -1;
                        let ans_id = "";
                        let ans_idx = -1;
                        let comment = "";
                        let _ans = [];
                        let isNone = false;
                        if (answers && answers.length > 0) {
                          q_idx = answers.findIndex(
                            (p) => p.questionId === item?.question?._id
                          );
                          ans_id = answers[q_idx]?.answerId
                            ? answers[q_idx]?.answerId
                            : "";
                          ans_idx = item?.question?.answers?.findIndex(
                            (op) => op?._id === ans_id
                          );
                          comment = answers[q_idx]?.comments
                            ? answers[q_idx]?.comments
                            : "";
                          _ans = answers[q_idx]?.ids ? answers[q_idx]?.ids : []; 
                          isNone = answers[q_idx]?.isNone
                            ? answers[q_idx]?.isNone
                            : false;                            
                        }
                        if (item?.isOpen)
                          return (
                            <>
                              <div className="space-y-3" key={index}>
                                <div className="flex items-center justify-start">
                                  <div className="text-sm font-bold text-slate-800">
                                    {" " + item?.question?.name}
                                  </div>
                                </div>
                                <div className="space-y-3">
                                  {item?.question?.fieldType &&
                                  item?.question?.fieldType === "select" ? (
                                    <Select
                                      xPlacement={"left"}
                                      selectedValue={
                                        index === q_idx &&
                                        item?.question?.answers[ans_idx]
                                          ?._id === ans_id
                                          ? item?.question?.answers[ans_idx]
                                              ?._id
                                          : ""
                                      }
                                      dropdownClass={"!w-full"}
                                      dropdownButtonLabelClass={"!font-bold"}
                                      dropdownData={[
                                        {
                                          _id: null,
                                          label: "Select Rating",
                                          value: "",
                                        },
                                        ...item?.question?.answers
                                          .sort((a, b) => b.value - a.value)
                                          .map((it, idx) => ({
                                            _id: it?._id,
                                            label:
                                              it?.value +
                                              " " +
                                              (it?.label
                                                ? "(" + it?.label + ")"
                                                : ""),
                                            value: it?.value,
                                            title: it?.label,
                                          })),
                                      ]}
                                      dropdownOptionBold={true}
                                      getSelectedValue={(e) =>
                                        handelUpdate(
                                          item?.question?._id,
                                          e._id,
                                          e.value,
                                          e.title
                                        )
                                      }
                                    />
                                  ) : item?.question?.fieldType &&
                                    item?.question?.fieldType === "checkbox" ? (
                                    <>
                                      {item?.question?.answers.length > 0 &&
                                        item?.question?.answers
                                          .filter((it) => !it?.company_id)
                                          .map((it, idx) => {
                                            return (
                                              <Checkbox
                                                checkboxLableClass={
                                                  "text-slate-800"
                                                }
                                                checkboxInputClass={""}
                                                checkboxClass={"!rounded-full"}
                                                divClass={"!items-start"}
                                                checkboxName={it?._id}
                                                isChecked={
                                                  index === q_idx &&
                                                  _ans.includes(it?._id)
                                                    ? true
                                                    : false
                                                }
                                                checkboxLabel={it?.description}
                                                checkboxValue={it?._id}
                                                getCheckboxValue={(
                                                  val,
                                                  event
                                                ) =>
                                                  updateHandler(
                                                    val,
                                                    event.target.checked,
                                                    item?.question?._id
                                                  )
                                                }
                                              />
                                            );
                                          })}
                                      <Checkbox
                                        checkboxLableClass={"text-slate-800"}
                                        checkboxInputClass={""}
                                        checkboxClass={"!rounded-full"}
                                        divClass={"!items-start"}
                                        checkboxName={"none"}
                                        isChecked={isNone ? true : false}
                                        checkboxLabel={t("none")}
                                        checkboxValue={"none"}
                                        getCheckboxValue={(val, event) =>
                                          updateHandler(
                                            val,
                                            event.target.checked,
                                            item?.question?._id
                                          )
                                        }
                                      />
                                      {item?.question?.answers.length > 0 &&
                                        item?.question?.answers
                                          .filter(
                                            (it) => it?.created_by === user?._id
                                          )
                                          .map((it2, idx2) => {
                                            return (
                                              <div className="flex items-center gap-4 relative">
                                                <div className="relative w-full">
                                                  <Input
                                                    label={""}
                                                    labelClasses={"!text-xs"}
                                                    inputType={"text"}
                                                    inputPlaceholder={t(
                                                      "enter_label"
                                                    )}
                                                    inputValue={
                                                      it2?.description
                                                    }
                                                    setInput={(val) =>
                                                      handelEveryAnswerUpdate(
                                                        "description",
                                                        val,
                                                        item?.question?._id,
                                                        it2?._id
                                                      )
                                                    }
                                                    //isDisabled={true}
                                                  />
                                                </div>
                                                <div className="flex items-center gap-2">
                                                  {/* <Button buttonIcon={"fa-light fa-edit"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-slate-600 "} buttonType={"button"} functions={(e) => editItem(it2?._id)} buttonHasLink={false} /> */}
                                                  <Button
                                                    buttonIcon={
                                                      "fa-light fa-save"
                                                    }
                                                    buttonIconPosition={"left"}
                                                    buttonClasses={
                                                      "!h-6 !w-6 !min-w-[24px] !text-sm !p-0 "
                                                    }
                                                    buttonType={"button"}
                                                    functions={(e) =>
                                                      saveItem(
                                                        "description",
                                                        item?.question?._id,
                                                        it2?._id
                                                      )
                                                    }
                                                    buttonHasLink={false}
                                                  />
                                                  <Button
                                                    buttonIcon={
                                                      "fa-light fa-times"
                                                    }
                                                    buttonIconPosition={"left"}
                                                    buttonClasses={
                                                      "!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500 "
                                                    }
                                                    buttonType={"button"}
                                                    functions={(e) =>
                                                      deleteItem(
                                                        item?.question?._id,
                                                        it2?._id
                                                      )
                                                    }
                                                    buttonHasLink={false}
                                                  />
                                                </div>
                                              </div>
                                            );
                                          })}
                                      <Button
                                        buttonLabel={t("other")}
                                        buttonClasses={
                                          "!text-[10px] !text-teal-600 !text-white"
                                        }
                                        buttonType={"button"}
                                        buttonIcon={
                                          "fa-regular fa-arrow-right-to-arc"
                                        }
                                        buttonIconPosition={"right"}
                                        buttonHasLink={false}
                                        functions={() => {
                                          //setEnabledOtherRatingOptionPopup(true);
                                          setIsShow(true);
                                          setCurrentQuestionId(
                                            item?.question?._id
                                          );
                                          setCurrentFieldType(
                                            item?.question?.fieldType
                                          );
                                        }}
                                      />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {isShow &&
                                  item?.question?.fieldType === "checkbox" && (
                                    <div className="py-3 px-6 border border-slate-200 rounded relative z-[1]">
                                      <Button
                                        buttonClasses={
                                          "!p-0 !flex !items-center !justify-center !text-rose-500 border border-slate-200 shadow rounded-full !w-8 !h-8 !text-lg absolute right-0 -top-3 -right-3 !bg-white z-[2]"
                                        }
                                        buttonType={"button"}
                                        buttonIcon={"fa-regular fa-xmark"}
                                        buttonIconPosition={"left"}
                                        buttonHasLink={false}
                                        functions={() => {
                                          setIsShow(false);
                                          setCurrentQuestionId("");
                                          setCurrentFieldType("");
                                          setAddonsAnswers([
                                            {
                                              label: "",
                                              value: "",
                                              description: "",
                                            },
                                          ]);
                                        }}
                                      />
                                      {addonsAnswers.map((it, key) => (
                                        <>
                                          {item?.question?.fieldType !==
                                            "checkbox" && (
                                            <div className="items-end gap-4 mb-4">
                                              <div className="grid sm:grid-cols-2 gap-4 relative">
                                                <div className="relative w-full">
                                                  <Input
                                                    label={t("label")}
                                                    labelClasses={"!text-xs"}
                                                    inputType={"text"}
                                                    inputPlaceholder={t(
                                                      "enter_label"
                                                    )}
                                                    inputValue={it?.label}
                                                    setInput={(val) =>
                                                      handelOtherUpdate(
                                                        "label",
                                                        val,
                                                        key
                                                      )
                                                    }
                                                    //setInput={setLabel}
                                                  />
                                                </div>
                                                <div className="relative w-full">
                                                  <Input
                                                    label={t("value")}
                                                    labelClasses={"!text-xs"}
                                                    inputType={"number"}
                                                    inputPlaceholder={t(
                                                      "enter_value"
                                                    )}
                                                    inputValue={it?.value}
                                                    setInput={(val) =>
                                                      handelOtherUpdate(
                                                        "value",
                                                        val,
                                                        key
                                                      )
                                                    }
                                                    //setInput={setValue}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          <div className="flex items-start gap-4 relative pr-8 sm:pr-10">
                                            <div className="relative w-full">
                                              <Textarea
                                                label={t("description")}
                                                inputClasses={"!h-16"}
                                                labelClasses={"!text-xs"}
                                                inputValue={it?.description}
                                                inputPlaceholder={t(
                                                  "enter_description"
                                                )}
                                                setTextarea={(val) => {
                                                  handelOtherUpdate(
                                                    "description",
                                                    val,
                                                    key
                                                  );
                                                  setCurrentQuestionId(
                                                    item?.question?._id
                                                  );
                                                  setCurrentFieldType(
                                                    item?.question?.fieldType
                                                  );
                                                }}
                                                //setTextarea={setDescription}
                                              />
                                            </div>
                                            {key === 0 ? (
                                              <Button
                                                buttonIcon={"fa-light fa-plus"}
                                                buttonIconPosition={"left"}
                                                buttonClasses={
                                                  "!h-6 !w-6 !min-w-[24px] !text-sm !p-0 absolute top-5 right-0"
                                                }
                                                buttonType={"button"}
                                                functions={(e) => addRow()}
                                                buttonHasLink={false}
                                              />
                                            ) : (
                                              <Button
                                                buttonIcon={"fa-light fa-minus"}
                                                buttonIconPosition={"left"}
                                                buttonClasses={
                                                  "!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500 absolute top-5 right-0"
                                                }
                                                buttonType={"button"}
                                                functions={(e) =>
                                                  removeRow(key)
                                                }
                                                buttonHasLink={false}
                                              />
                                            )}
                                          </div>
                                        </>
                                      ))}
                                      <div className="relative flex justify-center mb-3 mt-6 gap-4">
                                        <Button
                                          buttonClasses="!bg-teal-500 !px-3 !h-8 !text-sm !gap-1"
                                          buttonLabelClasses=""
                                          buttonType={"button"}
                                          buttonIcon={
                                            isAdd
                                              ? "fa-light fa-spinner fa-spin"
                                              : "fa-light fa-check"
                                          }
                                          buttonIconPosition={"left"}
                                          buttonLabel={t("save")}
                                          functions={onAddAnswer}
                                          // functions={() => {
                                          //   if(!label){
                                          //     toastr.error(t('label_field_is_required'));
                                          //   }else if(!description){
                                          //     toastr.error(t('description_field_is_required'));
                                          //   }else{
                                          //     onAddAnswer();
                                          //   }
                                          // }}
                                          buttonHasLink={false}
                                          buttonDisabled={isAdd}
                                        />
                                      </div>
                                    </div>
                                  )}
                              </div>
                              <div className="w-full">
                                <Textarea
                                  label={""}
                                  inputClasses={"!h-40"}
                                  labelClasses={"!text-xs"}
                                  inputValue={comment}
                                  inputPlaceholder={t("enter_comments")}
                                  setTextarea={(val) =>
                                    handelUpdate(
                                      item?.question?._id,
                                      "comments",
                                      val
                                    )
                                  }
                                  //onBlur={(val) => handelUpdate(item?.question?._id,'comments', val)}
                                  isAddBullet={true}
                                />
                              </div>
                              {/* <div className="flex items-center justify-between mt-2 -mx-2">
                                {
                                  (pastIndex > 0) &&
                                  <div className="w-1/2">
                                    <Button
                                      buttonLabel={"preview"}
                                      buttonLabelClasses={"text-slate-600 !capitalize"}
                                      buttonClasses={"!text-slate-600 !flex !items-center !justify-center !text-[10px] !bg-transparent !text-slate-400  "}
                                      buttonType={"button"}
                                      buttonIcon={"fa-regular fa-chevron-left"}
                                      buttonIconPosition={"left"}
                                      buttonHasLink={false}
                                      functions={() => changeIndex('past',index)}
                                    />
                                  </div>
                                }
                                {
                                  (nextIndex >= 0 &&  nextIndex < Number(reatingOptions.length-1)) && 
                                  <div className="w-1/2 flex justify-end ml-auto">
                                    <Button
                                      buttonLabel={t('continue')}
                                      buttonLabelClasses={"text-slate-600 !capitalize"}
                                      buttonClasses={"!text-slate-600 !flex !items-center !justify-center !text-[10px] !bg-transparent !text-slate-400  "}
                                      buttonType={"button"}
                                      buttonIcon={"fa-regular fa-chevron-right"}
                                      buttonIconPosition={"right"}
                                      buttonHasLink={false}
                                      functions={() => changeIndex('next',index)}                                  
                                    />
                                  </div>  
                                }                                
                            </div> */}
                              <div className="relative flex justify-center border-t border-slate-200 mt-4 pt-4 gap-4">
                                {curentIndex > 0 &&
                                  pastIndex < Number(reatingOptions.length) && (
                                    <Button
                                      buttonLabel={t("previous")}
                                      buttonLabelClasses={""}
                                      buttonClasses={"!bg-orange-400"}
                                      buttonType={"button"}
                                      buttonIcon={
                                        "fa-light fa-arrow-left-to-line"
                                      }
                                      buttonIconPosition={"left"}
                                      buttonHasLink={false}
                                      functions={() =>
                                        changeIndex("past", index)
                                      }
                                    />
                                  )}
                                {nextIndex >= 0 &&
                                  nextIndex <=
                                    Number(reatingOptions.length - 1) &&
                                  isDisabled && (
                                    <Button
                                      buttonLabel={t("continue")}
                                      buttonLabelClasses={""}
                                      buttonClasses={"!bg-teal-400  "}
                                      buttonType={"button"}
                                      buttonIcon={
                                        "fa-light fa-arrow-right-to-line"
                                      }
                                      buttonIconPosition={"right"}
                                      buttonHasLink={false}
                                      functions={() =>
                                        changeIndex("next", index)
                                      }
                                    />
                                  )}
                                {!isDisabled &&
                                  nextIndex ===
                                    Number(reatingOptions.length - 1) && (
                                    <Button
                                      buttonClasses={
                                        isDisabled
                                          ? "!bg-slate-200 !text-slate-600 border border-slate-300"
                                          : "!bg-teal-500"
                                      }
                                      buttonLabelClasses=""
                                      buttonType={"button"}
                                      buttonIcon={
                                        isConfirm
                                          ? "fa-light fa-spinner fa-spin"
                                          : "fa-light fa-check"
                                      }
                                      buttonIconPosition={"left"}
                                      buttonLabel={t("complete_survey")}
                                      functions={onSubmit}
                                      buttonHasLink={false}
                                      isDisabled={isDisabled}
                                    />
                                  )}
                                <Button
                                  buttonLabelClasses=""
                                  buttonType={"button"}
                                  buttonIcon={"fa-light fa-times"}
                                  buttonIconPosition={"left"}
                                  buttonLabel={t("exit_survey")}
                                  buttonClasses={"!bg-rose-500"}
                                  functions={() => {
                                    if (isConfirmation) {
                                      setEnabledConfirmationSurveyPopup(true);
                                    } else {
                                      closeModal();
                                    }
                                  }}
                                  //functions={closeModal}
                                  buttonHasLink={false}
                                />
                              </div>
                            </>
                          );
                      })
                    ) : loading ? (
                      <Searching label={t("searching")} />
                    ) : (
                      <NoDataFound label={t("no_board_member_found")} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {enabledOtherRatingOptionPopup && (
        <OtherRatingOptionPopup
          isOpen={enabledOtherRatingOptionPopup}
          setIsOpen={(val) => setEnabledOtherRatingOptionPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
            setCurrentQuestionId("");
          }}
          data={{
            questionId: currentQuestionId,
          }}
        ></OtherRatingOptionPopup>
      )}
      {enabledConfirmationSurveyPopup && (
        <SurveyConfirmationPopup
          isOpen={enabledConfirmationSurveyPopup}
          setIsOpen={(val) => setEnabledConfirmationSurveyPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></SurveyConfirmationPopup>
      )}
    </>
  );
};

export default SurveyPopup;
