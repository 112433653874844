import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import Image from "../../components/elements/Image";
import ButtonFile from "../../components/form/ButtonFile";
import Textarea from "../../components/form/Textarea";
import toastr from "toastr";
import { useNavigate } from "react-router";
import { useParams } from "react-router";

import { allowedImageExtensions } from "../../helpers";
import { postData } from "../../services/api";

import { useTranslation } from "react-i18next";

const ManageLeads = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Leads");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Leads");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");
  const [showLogo, setShowLogo] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState(1);
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [services, setServices] = useState([]);

  useEffect(() => {
    // Banner details
    const loadDetails = async () => {
      try {
        const result = await postData("lead-content/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
          setServices(
            result.data.content
              ? result.data.content
              : [
                  {
                    title: "",
                    creator: "",
                  },
                ]
          );
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, []);

  useEffect(() => {
    setName(details?.name);
    setSlug(details?.slug);
    setDescription(details?.description);
    setType(details?.type);
  }, [details]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setSlug(details?.sub_title);
      setDescription(details?.description);
      setType(details?.type);
    } else {
      setName("");
      setDescription("");
      setSlug("");
      setType(1);
    }
    setIsCancel(false);
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "lead-content/update";
        payload = {
          id: id,
          name: name,
          description: description,
          //image: /^data:.+\/(.+);base64,/.test(showLogo) ? showLogo : null,
          content: services,
        };
      } else {
        path = "lead-content/create";
        payload = {
          name: name,
          description: description,
          //image: /^data:.+\/(.+);base64,/.test(showLogo) ? showLogo : null,
          content: services,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/leads");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };

  const addRow = async () => {
    if (type === 1) {
      setServices((services) => [
        ...services,
        {
          title: "",
          creator: "",
        },
      ]);
    } else if (type === 2) {
      setServices((services) => [
        ...services,
        {
          title: "",
        },
      ]);
    }
  };
  const removeRow = async (index) => {
    setServices((services) => services.filter((item, i) => i !== index));
  };

  const handelUpdate = async (field, val, index) => {
    if (field === "title") {
      setServices((services) =>
        services.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              title: val,
            };
          } else {
            return item;
          }
        })
      );
    } else if (field === "creator") {
      setServices((services) =>
        services.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              creator: val,
            };
          } else {
            return item;
          }
        })
      );
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl ">
      <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
          <div className="text-2xl font-bold">{id ? t("edit_lead") : t("add_lead")}</div>
      </div>
      <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12  flex flex-col">
              <div className="space-y-4">
                <div className="grid sm:grid-cols-12 gap-4">
                  <div className="relative col-span-12 sm:col-span-6">
                    <Input label={t("name")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_name")} inputValue={name} setInput={setName} />
                  </div>
                  <div className="relative col-span-12 sm:col-span-6">
                    <Input label={t("slug")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_slug")} inputValue={slug} setInput={setSlug} isDisabled={true} />
                  </div>
                  <div className="relative col-span-12 ">
                    <Textarea label={t("description")} inputClasses={"!h-16"} labelClasses={"!text-xs"} inputValue={description} inputPlaceholder={t("enter_description")} setTextarea={setDescription} />
                  </div>
                  <div className="col-span-12"><div className="relative"><div className="text-lg font-bold">Services</div></div></div>
                  <div className="col-span-12 grid grid-cols-1 sm:grid-cols-12 gap-x-4 gap-y-3">
                      {services.map((item, key) => {
                        return (
                          <div className="pb-2 col-span-4" key={key}>
                            {/* title&&icon */}
                            <div className="flex items-end gap-2">
                              <div className="relative w-full">
                                <Input label={""} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_title")} inputValue={item?.title} setInput={(val) => handelUpdate("title", val, key)} />
                              </div>
                              {type && type === 1 && (
                                <div className="relative w-full">
                                  <Input label={key === 0 ? t("cretor") : ""} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_cretor")} inputValue={item?.creator} setInput={(val) => handelUpdate("creator", val, key)} />
                                </div>
                              )}
                              {key === 0 ? <Button buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !mt-2"} buttonType={"button"} functions={(e) => addRow()} buttonHasLink={false} /> : <Button buttonIcon={"fa-light fa-minus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500 mt-2"} buttonType={"button"} functions={(e) => removeRow(key)} buttonHasLink={false} />}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
               
              
                
              </div>

              <div className="flex justify-end gap-4 mt-5">
                <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={onCancel} buttonHasLink={false} buttonDisabled={isCancel} />
                <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-floppy-disk"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
              </div>
          </div>
      </div>
    </div>
  );
};

export default ManageLeads;
