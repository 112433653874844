const BASE_URL = "https://api.successionnow.com/api/v1/";
API_URL = "https://api.successionnow.com/";

NINEBOX_API_URL = "https://api.9boxnow.iosx.in/api/v1/";
NINEBOX_BASE_URL = "https://9boxnowv2.iosx.in";

ORGCHART_API_URL = "https://api.orgchrat.iosx.in/api/v1/";
ORGCHART_BASE_URL = "https://front.orgchart.iosx.in";

export default BASE_URL;
export var API_URL;
export var NINEBOX_API_URL;
export var ORGCHART_API_URL;
export var NINEBOX_BASE_URL;
export var ORGCHART_BASE_URL;
