import React, { useState, useEffect, useCallback, useRef } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import Search from "../../components/form/Search";
import Table from "../../components/elements/table/Table";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../helpers";
import BuyPlanPopup from "../../components/popup/BuyPlanPopup";
import toastr from "toastr";
import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";
import { Tooltip } from "react-tooltip";
import { useDebounce } from "../../helpers/useDebounce";
import { useNavigate } from "react-router-dom";
import ManagePasswordPopup from "../../components/popup/ManagePasswordPopup";
import { getEmailFilter, getStatusFilter } from "../../constant/Constant";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import "flatpickr/dist/l10n/default";
import { capitalizeString } from "../../helpers";
import MultipleButtonAction from "../../components/elements/multipleButtonAction/MultipleButtonAction";

const Companies = ({}) => {
  setTitle("Succession Planning Software for your Business with Succession Now | Companies");
  const navigate = useNavigate();
  const flatPicker = useRef(null);
  const { i18n, t } = useTranslation();
  const email_filter = getEmailFilter(t);
  const status_filter = getStatusFilter(t);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 500);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");

  const [emailVerified, setEmailVerified] = useState("");
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [noOfPage, setNoOfPage] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [passwordInfo, setPasswordInfo] = useState({});
  const [enabledPasswordManagePopup, setEnabledPasswordManagePopup] = useState(false);
  const [companyList, setCompenyList] = useState([]);
  const [date, setDate] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const role = localStorage.getItem("role");
  const [plans, setPlans] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState([]);

  const othersAction = {
    type: "button",
    link: "/",
    label: t("payment_status"),
    icon: "fa-hand-holding-dollar",
    isVisabled: 2,
  };

  const extraData = [
    // {
    //   _id: 1,
    //   label: t("subscriptions"),
    //   icon: "fa-money-simple-from-bracket",
    //   link: "/subscriptions",
    //   type: "button",
    //   standout: false,
    //   buttonType: "subscriptions",
    //   isVisabled: 1,
    //   ordering: 8,
    // },
    {
      _id: 1,
      label: t("View Details"),
      icon: "fa-regular fa-eye",
      type: "button",
      standout: false,
      buttonType: "viewDetails",
      isVisabled: 1,
      ordering: 1,
    },
    role &&
      role === "master-admin" && {
        _id: 5,
        label: t("update_password"),
        icon: "fa-key",
        link: "/",
        type: "button",
        standout: false,
        buttonType: "update-password",
        isVisabled: 1,
        ordering: 5,
      },
  ];

  const [enabledBuyPlanPopup, setEnabledBuyPlanPopup] = useState(false);
  const [companyData, setCompanyData] = useState({});

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "status") {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("usertype/status-change", {
            id: actionValue?.row_id,
            status: actionValue?.current_status,
          });
          if (statusData.data) {
            setCompanies(
              companies.map((it) => {
                if (it?._id === statusData.data?._id) {
                  return {
                    ...it,
                    status: statusData.data?.status,
                  };
                } else {
                  return it;
                }
              })
            );
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("usertype/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = companies.findIndex((item) => item._id === actionValue?.row_id);
            if (index !== -1) {
              companies.splice(index, 1);
              setCompanies(
                companies.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    } else if (actionValue && actionValue.button_type === "others") {
      setEnabledBuyPlanPopup(true);
      setCompanyData(actionValue);
    } else if (actionValue && actionValue.button_type === "subscriptions") {
      navigate(`/subscriptions?company=${actionValue?.row_id}`);
    } else if (actionValue && actionValue.button_type === "viewDetails") {
      navigate(`/company/${actionValue?.row_id}`);
    } else if (actionValue && actionValue.button_type === "update-password") {
      setEnabledPasswordManagePopup(true);
      const data = companyList?.filter((item) => item._id === actionValue?.row_id);
      setPasswordInfo({
        _id: actionValue?.row_id,
        data: data,
      });
      setIsUpdate(false);
    }
  }, [actionValue]);

  //Company List Api Call
  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("usertype/list", {
        keyword: search,
        limit: limit,
        offset: offset,
        sortQuery: sortQuery,
        email_verified: emailVerified,
        status: status,
        accountType: accountType,
        code: "customer-admin",
        date: date,
        plan: selectedPlan,
      });

      if (result.data) {
        setCompanies(result?.data);
        setCompenyList(result?.data);
      }
      setTotalDataCount(result.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [search, limit, offset, sortQuery, emailVerified, status, accountType, isUpdate, date, selectedPlan]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const tableData =
    companies &&
    Array?.isArray(companies) &&
    companies.length > 0 &&
    companies?.map((value, key) => {
      const subscriptionDetails = Array?.isArray(value?.subscription_details) && value?.subscription_details?.length > 0 && value?.subscription_details;
      let successionPlanCount = value?.features && value?.features?.length > 0 && value?.features?.find((it) => it?.code === "succession-plans-per-year")?.inputValue;
      let noOfSuccessionPlan = 0;
      // value?.individual_details.filter(
      //   (it) => it.conclusion_details.length > 0
      // );
      return {
        _id: value?._id,
        rowData: [
          {
            _id: 1,
            width: 32,
            type: "user",
            isTooltip: true,
            shortName: value?.company_name,
            image: value?.image,
            toolTipData: (
              <div className="flex flex-col items-start justify-center text-white gap-1">
                {value?.name && (
                  <h4 className="font-semibold">
                    <span className="text-slate-200">Name: </span>
                    {value?.name}
                  </h4>
                )}

                {value?.email && (
                  <h4 className="font-medium">
                    <span className="text-slate-200">Email: </span>
                    {value?.email}
                  </h4>
                )}

                {value?.phone && (
                  <h4 className="font-medium">
                    <span className="text-slate-200">Phone: </span>
                    {value?.phone}
                  </h4>
                )}
                {value?.account_type && (
                  <h4 className="flex font-medium space-x-2">
                    <span className="text-slate-200">Account Type: </span>
                    <div className="flex items-center">
                      <div
                        className={`text-[10px] font-medium leading-tight rounded py-1 px-2  ${value?.account_type === "business" ? "bg-violet-100 text-violet-600" : "bg-orange-100 text-orange-600"}`}
                      >
                        {capitalizeString(value?.account_type)}
                      </div>
                    </div>
                  </h4>
                )}
                {value?.subscription_details.length > 0 && (
                  <h4 className="flex font-medium space-x-2">
                    <span className="text-slate-200">Subscription Details: </span>
                    <div className="flex items-center">
                      <div
                        className={`text-[10px] font-medium leading-tight rounded py-1 px-2  ${
                          value?.subscription_details[0].status === 1 ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600"
                        }`}
                      >
                        {value?.subscription_details[0].status === 1 ? t("paid") : t("unpaid")}
                      </div>
                    </div>
                  </h4>
                )}
                {value?.number_of_employees && (
                  <h4 className="font-medium">
                    <span className="text-slate-200">Number of employees: </span>

                    {value?.number_of_employees}
                  </h4>
                )}
                <h4 className="font-medium">
                  <span className="text-slate-200">Plan Name: </span>

                  {value?.plan_info?.name || "N/A"}
                </h4>
              </div>
            ),
            data: {
              _id: value?._id,
              name: value?.company_name,
              link: `/company/${value?._id}`,
            },
          },
          {
            _id: 2,
            width: 10,
            type: "text",
            // data: value?.name,
            data: (
              <div className="relative flex flex-col">
                {value?.name || "N/A"}
                <small className="text-sky-700">{value?.email || "N/A"}</small>
              </div>
            ),
          },
          // { _id: 3, width: 15, type: "text", data: value?.email },
          {
            _id: 4,
            width: 15,
            type: "text",
            data: (
              <>
                <div className="flex items-center">
                  <div className={`text-[10px] font-medium leading-tight rounded py-1 px-2  ${value?.account_type === "business" ? "bg-violet-100 text-violet-600" : "bg-orange-100 text-orange-600"}`}>
                    {value?.account_type ? capitalizeString(value?.account_type) : ""}
                  </div>
                </div>
              </>
            ),
            isColor: "",
          },
          {
            _id: 5,
            width: 15,
            type: "text",
            data: (
              <div className="relative flex flex-col text-[10px] items-start text-center text-slate-400 " data-tooltip-id={`subscriptionDetails${value?._id}`} data-tooltip-place="right">
                <div>
                  <span className="text-[10px] font-semibold">Original Price: </span>
                  <span className="text-teal-500">
                    {(Array.isArray(value?.subscription_details) && value?.subscription_details.length > 0 && value?.subscription_details[0]?.original_price) || 0.0}
                  </span>
                </div>
                <div>
                  <span className="text-[10px] font-semibold">Price: </span>
                  <span className="text-teal-500">{(Array.isArray(value?.subscription_details) && value?.subscription_details.length > 0 && value?.subscription_details[0]?.price) || 0.0}</span>
                </div>
                <Tooltip
                  id={`subscriptionDetails${value?._id}`}
                  className="!text-xs !bg-teal-500 z-50 shadow-lg rounded py-3 px-6 !opacity-100"
                  render={() => (
                    <>
                      <div className="flex flex-col items-start justify-center text-white gap-1">
                        <div>
                          <span className="text-[13px] font-semibold">Original Price: </span>
                          <span className="font-bold">
                            {(Array.isArray(value?.subscription_details) && value?.subscription_details.length > 0 && value?.subscription_details[0]?.original_price) || 0.0}
                          </span>
                        </div>
                        <div>
                          <span className="text-[13px] font-semibold">Price: </span>
                          <span className="font-bold">{(Array.isArray(value?.subscription_details) && value?.subscription_details.length > 0 && value?.subscription_details[0]?.price) || 0.0}</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <span className="text-[13px] font-semibold">Payment: </span>
                          <div
                            className={`text-[10px] font-medium leading-tight rounded py-1 px-2  ${
                              value?.subscription_details.length > 0 && value?.subscription_details[0].status === 1 ? "bg-lime-300 text-lime-900" : "bg-rose-100 text-rose-600"
                            }`}
                          >
                            {value?.subscription_details.length > 0 && value?.subscription_details[0].status === 1 ? t("paid") : t("unpaid")}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            ),
          },
          {
            _id: 19,
            width: 10,
            type: "text",
            // data: value?.name,
            data: <div className="relative flex flex-col text-orange-500  text-[12px]">{value?.plan_info?.name || "N/A"}</div>,
          },
          // {
          //   _id: 17,
          //   width: 40,
          //   type: "text",
          //   isColor: "",
          //   data: (
          //     <div
          //       className="relative flex flex-col items-center text-center"
          //       data-tooltip-id={`currentPlan${value?._id}`}
          //       data-tooltip-place="right"
          //     >
          //       {value?.number_of_succession_plan
          //         ? `${noOfSuccessionPlan.length}/${value?.number_of_succession_plan}`
          //         : "0/1"}
          //       <small className="font-bold text-teal-500">
          //         {`${
          //           successionPlanCount && successionPlanCount >= 100000
          //             ? "Unlimited"
          //             : successionPlanCount
          //         }
          //          Succession Plan`}
          //       </small>
          //       <Tooltip
          //         id={`currentPlan${value?._id}`}
          //         className="!text-xs !bg-teal-500 z-50 shadow-lg rounded py-3 px-6 !opacity-100"
          //         render={() => (
          //           <>
          //             <div className="flex flex-col items-start justify-center text-white gap-1">
          //               <h4 className="font-medium">
          //                 <span className="text-slate-200">
          //                   Succession Plan:{" "}
          //                 </span>
          //                 {successionPlanCount &&
          //                 successionPlanCount >= 100000
          //                   ? "Unlimited"
          //                   : successionPlanCount}
          //               </h4>
          //               <h4 className="font-semibold">
          //                 <span className="text-slate-200">
          //                   Completed Succession Plan:{" "}
          //                 </span>
          //                 {noOfSuccessionPlan.length || 0}
          //               </h4>

          //               <h4 className="font-medium">
          //                 <span className="text-slate-200">
          //                   Number Of Succession Plan:{" "}
          //                 </span>
          //                 {value?.number_of_succession_plan || 1}
          //               </h4>
          //             </div>
          //           </>
          //         )}
          //       />
          //     </div>
          //   ),
          // },
          {
            _id: 6,
            width: 20,
            type: "text",
            data: value && value?.created_at ? moment(value?.created_at).format("ll") : "N/A",
          },
          {
            _id: 7,
            width: 15,
            type: "text",
            data: (
              <>
                <div className="flex items-center" data-tooltip-id={`email${value?._id}`} data-tooltip-place="right">
                  <div
                    className={`flex items-center text-[10px] font-medium leading-tight rounded py-1 px-2 ${
                      value?.email_verified === 1 ? "bg-green-100 text-green-600" : "bg-amber-100 text-amber-600"
                    }`}
                  >
                    <i className="fa-regular fa-envelope mr-1"></i>
                    {value?.email_verified === 1 ? t("verified") : t("not_verified")}
                  </div>
                  <Tooltip
                    id={`email${value?._id}`}
                    className="!text-xs !bg-teal-500 z-50 shadow-lg rounded py-3 px-6 !opacity-100"
                    render={() => (
                      <div className="gap-x-1">
                        <div className="text-[13px] font-normal leading-none flex items-center">
                          <div className="font-semibold">
                            {value?.email_verified === 1 ? "Company that has accepted the email invitation" : "Company that has not yet accepted the email invitation"}
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </>
            ),
          },
          {
            _id: 8,
            width: 15,
            type: "status",
            statusLabel: value?.subscription_details.length > 0 && value?.subscription_details[0].status === 1 ? t("paid") : t("unpaid"),
            statusType: value?.subscription_details.length > 0 && value?.subscription_details[0].status === 1 ? "success" : "danger",
          },
          {
            _id: 9,
            width: 15,
            type: "status",
            statusLabel: value?.status && value?.status === 1 ? t("active") : t("deactivated"),
            statusType: value?.status && value?.status === 1 ? "success" : "warning",
            isTooltip: true,
            toolTipData: (
              <div className="flex flex-col items-start justify-center text-white gap-1">
                {value?.name && <div className="font-semibold text-slate-200 text-sm">Company that can be login into the successionnow portal</div>}
              </div>
            ),
            data: { _id: value?._id },
          },
          {
            _id: 10,
            width: 10,
            type: "action",
            statusLabel: value?.status && value?.status === 1 ? t("active") : t("deactivated"),
            statusType: value?.status && value?.status === 1 ? "success" : "warning",
            data: [
              {
                others: value?.subscription_details && value?.subscription_details.length > 0 ? 1 : 2,
              },
              { subscriptions: 1 },
              { viewDetails: 1 },
              { "update-password": 1 },
            ],
          },
        ],
      };
    });

  //Pagination Logic
  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    setCurrentPage(event.selected);
    setOffset(newOffset);

    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    setSelectedRow([]);
  };

  const tableHeadData = [
    {
      _id: 1,
      width: 32,
      name: t("company"),
      value: "name",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 2,
      width: 10,
      name: t("Admin name"),
      value: "name",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    // {
    //   _id: 3,
    //   width: 15,
    //   name: t("Admin email"),
    //   value: "email",
    //   align: "left",
    //   isSort: true,
    //   isFilter: false,
    // },
    {
      _id: 4,
      width: 15,
      name: t("account"),
      value: "account_type",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 5,
      width: 15,
      name: t("subscription"),
      value: "promo_code",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 19,
      width: 10,
      name: t("plan"),
      value: "planName",
      align: "left",
      isSort: false,
      isFilter: true,
      filterData:
        (plans &&
          Array?.isArray(plans) &&
          plans?.map((item) => ({
            value: item?._id,
            name: item?.name,
            type: "checkbox",
          }))) ||
        [],
      onFilter: (data) => {
        if (data && Array.isArray(data) && data.length > 0) {
          setSelectedPlan(data?.map((item) => item?.value));
        } else {
          setSelectedPlan([]);
        }
        setOffset(0);
      },
    },
    // {
    //   _id: 17,
    //   width: 40,
    //   name: t("of_completed_plans"),
    //   value: "number_of_succession_plan",
    //   align: "left",
    //   isSort: false,
    //   isFilter: false,
    // },
    {
      _id: 6,
      width: 20,
      name: t("created"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 7,
      width: 15,
      name: t("verified"),
      value: "email_verified",
      align: "left",
      isSort: false,
      isFilter: true,
      filterData: email_filter || [],
      onFilter: (data) => {
        if (data && Array.isArray(data) && data.length === 1) {
          setEmailVerified(data[0]?.value);
        } else {
          setEmailVerified("");
        }
        setOffset(0);
      },
    },
    {
      _id: 8,
      width: 15,
      name: t("Payment"),
      value: "industry",
      align: "left",
      isSort: false,
      isFilter: false,
    },

    {
      _id: 9,
      width: 15,
      name: t("status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: true,
      filterData: status_filter || [],
      onFilter: (data) => {
        if (data && Array.isArray(data) && data.length === 1) {
          setStatus(data[0]?.value);
        } else {
          setStatus("");
        }
        setOffset(0);
      },
    },
    {
      _id: 10,
      width: 10,
      name: t("action"),
      value: "",
      align: "left",
      isSort: false,
      isFilter: false,
    },
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(companies, result.source.index, result.destination.index);
    setCompanies(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const handleReady = (selectedDates, dateStr, instance) => {
    const clearButton = document.createElement("div");
    clearButton.className = "clear-button";
    clearButton.textContent = "Clear";
    clearButton.addEventListener("click", () => {
      instance.clear();
    });
    instance.calendarContainer.appendChild(clearButton);
  };

  const handleClear = () => {
    setDate("");
    setSelectedDates([]);
    flatPicker.current.flatpickr.clear();
  };

  useEffect(() => {
    const loadPlans = async () => {
      setLoading(true);
      try {
        const result = await postData("plans/list", {
          isView: 1,
        });
        if (result.data) {
          setPlans(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPlans();
  }, []);

  const convertToCsv = (data) => {
    const header = Object.keys(data[0])
      .map((header) => header.toUpperCase())
      .join(",");

    const rows = data.map((row) => {
      return Object.values(row)
        .map((value) => {
          if (typeof value === "string" && value.includes(",")) {
            return `"${value}"`;
          }
          return value;
        })
        .join(",");
    });

    return [header, ...rows].join("\n");
  };
  const downloadFile = (csvData, filename) => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    }
  };

  /* Development Note: Retain the downloadCsv comment below for debugging and future development purposes */

  // const downloadCsv = async () => {
  //   try {
  //     const result = await postData("usertype/list", {
  //       keyword: search,
  //       limit: limit,
  //       offset: offset,
  //       sortQuery: sortQuery,
  //       email_verified: emailVerified,
  //       status: status,
  //       accountType: accountType,
  //       code: "customer-admin",
  //       date: date,
  //       plan: selectedPlan,
  //     });

  //     if (result.data) {
  //       const filteredData = result?.data?.map((item, index) => ({
  //         _id: item?._id,
  //         name: item?.name,
  //         email: item?.email,
  //         company_name: item?.company_name,
  //       }));
  //       const csvData = convertToCsv(filteredData);

  //       downloadFile(csvData, "succession_company.csv");
  //     }
  //   } catch (err) {
  //     console.log(err.message);
  //   } finally {
  //   }
  // };

  // useEffect(() => {
  //   // Create a new EventSource for receiving SSE
  //   const eventSource = new EventSource('https://api.successionnow.com/api/v1/get-response');
  //   // Handle incoming messages from the server
  //   eventSource.onmessage = (event) => {
  //     //setMessage(event.data);  // Update state with the event data
  //     console.log(event.data)
  //   };
  //   // Handle any errors
  //   eventSource.onerror = (error) => {
  //     console.error("EventSource failed:", error);      
  //   };
  //   // Cleanup function to close the connection when the component unmounts
  //   return () => {
  //     eventSource.close();
  //   };
  // }, []);

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3 gap-4">
        <div className="text-2xl font-bold">{t("companies")}</div>
        <div className="sm:ml-auto flex justify-end items-center" data-tooltip-id={`button${1}`} data-tooltip-place="right">
          <Button buttonType={"button"} buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonLabel={t("add")} buttonHasLink={true} buttonLink={"/new-account"} />
          <Tooltip
            id={`button${1}`}
            className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6 !opacity-100"
            render={() => (
              <div className="gap-x-1">
                <div className="text-[13px] font-normal leading-none flex items-center">
                  <div className="font-semibold">Add New Company Setup</div>
                </div>
              </div>
            )}
          />
        </div>
        {/* Development Note: Retain the downloadCsv comment below for debugging and future development purposes  */}
        {/* <Button
          buttonType={"button"}
          buttonLabel={"Developer Download Company"}
          buttonClasses={""}
          buttonHasLink={false}
          functions={downloadCsv}
        /> */}
      </div>

      <div className="relative flex gap-3 items-center mb-3 flex-wrap">
        <div className="relative">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            dropdownClass={"w-auto"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => {
              setLimit(e.value);
              setOffset(0);
              setCurrentPage(0);
              setSelectedRow([]);
            }}
          />
        </div>
        <div className="relative w-48">
          <Search
            placeholder={t("search")}
            setInput={setKeyword}
            valueReset={() => {
              setOffset(0);
              setCurrentPage(0);
              setSelectedRow([]);
            }}
          />
        </div>
        <div className="relative w-48">
          <Select
            xPlacement={"right"}
            dropdownButtonClass={"!bg-white"}
            dropdownClass={"w-48"}
            transitionClass={"!w-auto"}
            selectedValue={accountType}
            dropdownData={[
              { _id: "", label: t("select_account_type"), value: "" },
              {
                _id: "business",
                label: capitalizeString("business"),
                value: "business",
              },
              {
                _id: "demonstration",
                label: capitalizeString("demonstration"),
                value: "demonstration",
              },
              {
                _id: "consultant",
                label: capitalizeString("consultant"),
                value: "consultant",
              },
              {
                _id: "developer",
                label: capitalizeString("developer"),
                value: "developer",
              },
              {
                _id: "training",
                label: capitalizeString("training"),
                value: "training",
              },
            ]}
            getSelectedValue={(e) => {
              setAccountType(e.value);
              setOffset(0);
              setCurrentPage(0);
              setSelectedRow([]);
            }}
          />
        </div>
        <div className="relative">
          <div className="min-w-[192px] max-w-fit h-10 flex items-center rounded-md bg-transparent border border-slate-200  !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200 overflow-hidden">
            <Flatpickr
              ref={flatPicker}
              className="px-3  text-[13px] text-slate-500 font-medium whitespace-nowrap truncate border-0 h-full"
              placeholder={t("select_date")}
              value={date}
              options={{
                mode: "range",
                dateFormat: "Y-m-d",
                onClose: (selectedDates) => {
                  setSelectedDates(selectedDates);
                },
              }}
              onChange={(selectedDates, dateStr, instance) => {
                setDate(dateStr);
                setOffset(0);
                setCurrentPage(0);
                setSelectedRow([]);
              }}
              onReady={handleReady}
            />
            {date && (
              <button type="button" onClick={handleClear} className="h-full p-2 text-[16px] text-slate-400 font-medium">
                <i className="fa-solid fa-xmark "></i>
              </button>
            )}
          </div>
        </div>
        <div className="relative">
          <MultipleButtonAction
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            list={companies}
            setList={setCompanies}
            loadList={loadList}
            API_URL_STATUS={"usertype/status-change"}
            API_URL_DELETE={"usertype/delete"}
          />
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow overflow-hidden">
          <Table
            tableData={tableData}
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            //editUrl={'/edit-company'}
            isStatusUpdate={true}
            isDeleteEnabled={true}
            getActionValue={(obj) => setActionValue(obj)}
            othersAction={othersAction}
            extraData={extraData}
            isDraggable={false}
            // isUser={"/users"}
            loginAs={"/login-as"}
            totalDataCount={totalDataCount}
            tableClasses={"min-h-[400px]"}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            selectable={true}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <Pagination handlePageClick={handlePageClick} noOfPage={noOfPage} startDataCount={startDataCount} endDataCount={endDataCount} count={totalDataCount} forcePage={currentPage} />
      ) : (
        <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {enabledBuyPlanPopup && (
        <BuyPlanPopup
          isOpen={enabledBuyPlanPopup}
          setIsOpen={(val) => setEnabledBuyPlanPopup(val)}
          data={companyData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          type={"predictors"}
        ></BuyPlanPopup>
      )}
      {enabledPasswordManagePopup && (
        <ManagePasswordPopup
          isOpen={enabledPasswordManagePopup}
          setIsOpen={(val) => setEnabledPasswordManagePopup(val)}
          data={passwordInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledPasswordManagePopup(false);
            //setCompanyInfo({})
          }}
        />
      )}
    </div>
  );
};
export default Companies;
